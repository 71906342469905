import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Translate from '../../../../constants/translate'
import { InputText, ReactSelect } from '../../../../components'
import CommonHelper from '../../../../services/common'
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import { revenueTargets } from '../../../dealsLog/viewModel'


const AddTarget = (props) => {
    const [loader, setloader] = useState(false)
    const [years, setYears] = useState([])
    const [activeMonth, setActiveMonth] = useState(moment().format("MMM"))
    const [selectedYear, setSelctedYear] = useState(moment().format("YYYY"))
    const [selectedTarget, setSelectedTarget] = useState(props.target || {})
    const [copyloader, setCopyLoader] = useState(false)
    const [yearloader, setYearLoader] = useState(false)

    const handleyearChange = (e, data) => {
        setSelctedYear(e.value)

        if (e.value === moment().format('YYYY')) {
            setActiveMonth(moment().format('MMM'))
        }
        else {
            setActiveMonth(moment().startOf('year').format('MMM'))
        }
    }

    useEffect(() => {
        let _years = []
        _years.push({
            label: moment().add(-1, 'year').format('YYYY'),
            value: moment().add(-1, 'year').format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().format('YYYY'),
            value: moment().format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().add(1, 'year').format('YYYY'),
            value: moment().add(1, 'year').format('YYYY'),
            active: true,
        })
        setYears(_years)
    }, [])

    const handleCopyfromlastYear = async () => {
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[(parseInt(selectedYear) - 1)])) {
            Swal.fire(`${CommonHelper.showLocale(props, 'No targets defined in')} ${parseInt(selectedYear) - 1}`, '', 'error')
            return
        }
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(props, 'Do you want to copy targets from')} ${(parseInt(selectedYear) - 1)}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }
        setYearLoader(true)
        var _currentTarget = selectedTarget;
        for (let i = (Number(moment().month(activeMonth).format("M")) + 1); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[(parseInt(selectedYear) - 1)]
                }
            }
        }
        setSelectedTarget(_currentTarget)

        setTimeout(function () {
            toast.notify('Targets copied successfully.', {
                duration: 1000
            })
            setYearLoader(false)
        }, 2000)
    }

    const handleCopytoAll = () => {
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[selectedYear]) || _.isEmpty(selectedTarget[selectedYear][activeMonth])) {
            Swal.fire(CommonHelper.showLocale(props, 'Please define target in current month'), '', 'error')
            return
        }
        setCopyLoader(true)
        var _currentTarget = selectedTarget;
        const _targetMonth = Number(moment().format("M")) < Number(moment().month(activeMonth).format("M")) ? moment().format("MMM") : activeMonth;
        for (let i = Number(moment().month(_targetMonth).format("M")); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[selectedYear],
                    [moment(i.toString(), 'M').format('MMM')]: selectedTarget[selectedYear][activeMonth]
                }
            }
        }
        setSelectedTarget(_currentTarget)

        setTimeout(function () {
            toast.notify('Targets copied successfully.', {
                duration: 1000
            })
            setCopyLoader(false)
        }, 2000)


    }

    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setSelectedTarget({
                ...selectedTarget,
                [selectedYear]: {
                    ...(selectedTarget?.[selectedYear] || {}),
                    [activeMonth]: {
                        ...(selectedTarget?.[selectedYear]?.[activeMonth] || {}),
                        [name]: Number(value) || null
                    }

                }
            })
        }
    }

    const handleSave = () => {
        setloader(true)
        setTimeout(function () {
            props.handleClose(_.map(props.departments, function (obj, i) {
                return i === props.index ? {
                    ...obj,
                    targets: selectedTarget
                } : obj;
            }))
        }, 1000)

    }

    return <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-add-user"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title><Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="settings-head form-style border-top pt-3">
                <div className="form-row">
                    <div className="form-group col-md-3">
                        <ReactSelect
                            options={years}
                            name={"year"}
                            placeholder={'select year'}
                            onChange={handleyearChange}
                            value={selectedYear}
                            classNamePrefix={`basic-select`}
                            removeClearable={true}
                            id={`target-year`}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-12 float-right">
                        <div className="float-right ml-2">
                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                handleCopyfromlastYear()
                            }} >
                                {
                                    yearloader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }<Translate text={'Copy from last year'} /></button>
                        </div>
                        <div className="float-right">
                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                handleCopytoAll()
                            }} >
                                {
                                    copyloader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }<Translate text={'Copy to all months'} /></button>
                        </div>
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="form-group col-md-6">
                        <div className="yeargrid">
                            <ul>
                                {
                                    moment.monthsShort().map((rec, index) => {
                                        return <li key={index}><a
                                            id={rec}
                                            href="#"
                                            className={rec === activeMonth ? 'selected' : ''}
                                            onClick={(event) => {
                                                if (event.target.id) {
                                                    setActiveMonth(event.target.id)
                                                }
                                                event.preventDefault();
                                            }}
                                        ><Translate text={rec} /></a></li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                {
                    revenueTargets.filter(a => a.target === true).map((rec, index) => {
                        return <div key={index} className='form-group col-md-6'>
                            <label><Translate text={rec.name} /></label>
                            <InputText
                                autoComplete="off"
                                placeholder={`${rec.value} target`}
                                className={`form-control`}
                                name={rec.value}
                                onChange={handleNumberChange}
                                value={selectedTarget?.[selectedYear]?.[activeMonth]?.[rec.value]}
                                id={`target-${rec.value}`}
                            />
                        </div>
                    })
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => { handleSave() }}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'Save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
}

export default AddTarget