import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import { InputText, ReactSelect } from '../../components';
import _ from 'lodash';
import { firestoreDB } from '../../services/helper';

const CustomModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(_.pick(props.colDef.nestedObj ? props.data[props.colDef.nestedObj] : props.data, props?.modalFields?.map(r => { return r.fieldName })))
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  const handleClose = () => {
    props.api.stopEditing();
    setShow(false)
  }

  const handleSelectChange = (e, data) => {
    setState({
      ...state,
      [data.name]: e?.value || null,
      [`${data.name}Name`]: e?.label || null
    })
  }

  const handleMultiSelectChange = (selectedOptions, data) => {
    const value = [];
    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
      value.push(data.value)
    })
    setState({
      ...state,
      [data.name]: value
    })
  }

  const handleOnChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    });
  }

  const handleSubmit = () => {
    setLoader(true)
    _.chain(props?.modalFields)
      .groupBy('collectionName')
      .map((value, key) => ({
        collection: key,
        docID: value[0].docID,
        fields: value
      }))
      .value()
      .forEach(rec => {
        const objData = {};
        rec?.fields?.forEach(rec => {
          if (state[rec.fieldName] && rec.fieldDB)
            objData[rec.fieldDB] = state[rec.fieldName]
        })
        if (objData && rec?.collection && props?.data?.[rec?.docID]) {
          firestoreDB({ client: { projectId: props.data.projectId } }).firestore().doc(`${rec.collection}/${props.data[rec.docID]}`)
            .update(objData);
        }
      });
    let _value = '';
    props?.modalFields?.filter(a => a.type && (props.data.businessContact ? a.collectionName === 'companies' : a.collectionName === 'contactDetails'))?.forEach(rec => {
      if (state[rec.fieldName])
        _value += state[`${rec.fieldName}${rec.type === 'select' ? 'Name' : ''}`] + ' ';
    })
    //_value = <><i className={`ico icon-${props.data.businessContact ? 'company' : 'Contacts'} mr-1`}></i>{_value}</>
    props.api.stopEditing();
    props.api.getRowNode(props.rowIndex).updateData({ ...props.data, ...state, [props.colDef.value]: _value })
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      backdropClassName={show ? 'overlay-modal active' : ''}
      dialogClassName={`modal-dialog-centered modal-add-lookup`}
    >
      <Modal.Header>
        <Modal.Title> <Translate text={props.colDef.headerName} /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="form-style w-100">
              <div className="form-row">
                {
                  props?.modalFields?.filter(a => a.type && (props.data.businessContact ? true : a.collectionName === 'contactDetails'))?.map((field, index) => {
                    return <div key={index} className={`form-group ${field.class}`}>
                      <label > <Translate text={field.name} /></label>
                      {
                        field.type === 'select' ? (
                          <ReactSelect
                            options={props?.dealerSettings?.[field.settingsField]?.map(r => { return { ...r, label: r.name } }) || []}
                            name={field.fieldName}
                            placeholder={`select ${field.placeholder || ''}`}
                            onChange={handleSelectChange}
                            value={state[field.fieldName]}
                            classNamePrefix={`basic-select`}
                          >
                          </ReactSelect>
                        ) : field.type === 'multiselect' ? (
                          <ReactSelect
                            options={props?.dealerSettings?.[field.settingsField]?.map(r => { return { ...r, label: r.name } }) || []}
                            name={field.fieldName}
                            placeholder={`select ${field.placeholder || ''}`}
                            onChange={handleMultiSelectChange}
                            value={state[field.fieldName]}
                            classNamePrefix={`basic-select`}
                            isMulti={true}
                            isSearchable={true}
                          >
                          </ReactSelect>
                        ) : (
                          <InputText
                            autoComplete="off"
                            placeholder={field.placeholder || ''}
                            className={`form-control`}
                            name={field.fieldName}
                            onChange={handleOnChange}
                            value={state[field.fieldName]}
                          />
                        )
                      }

                    </div>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => handleSubmit(e)}>
          {
            loader ?
              (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
              : (<></>)
          }
          <Translate text={'save'} />
        </button>
        <button type="button" className="btn btn-default float-right" onClick={() => handleClose()} > <Translate text={'cancel'} /></button>
      </Modal.Footer>
    </Modal >
  );
});

export default CustomModal;