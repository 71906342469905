import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { ContentOverlay, ReactSelect } from "../../../../components";
import images from "../../../../images";
import { defaultEventCategories, eventspropipelineVM } from '../../company/oemSetup/viewModel';
import { ClientContext } from '../../../layout/clientContext'

const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

const ModuleAddOns = (props) => {
    const [loader, setLoader] = useState(true)
    const [selectedOEM, setSelectedOEM] = useState(null)
    const [modules, setModules] = useState({});
    const [btnLoader, setBtnLoader] = useState({});

    const [selectedLevel, setSelectedLevel] = useState({ level: 'oem' })

    const [oemOptions, setOEMOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    const { oemSettings } = useContext(ClientContext);


    useEffect(() => {
        if (_.isEmpty(oemSettings) || selectedOEM)
            return

        let _oemSettings = [];
        oemSettings.forEach((rec) => {
            _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
        })
        setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
        if (_oemSettings.length > 0)
            setSelectedOEM(_oemSettings[0].data)
    }, [oemSettings])

    // fetching the oems clients, regions, groups list if ome is selected 
    useEffect(() => {
        if (_.isEmpty(selectedOEM))
            return

        const fetchOptions = async () => {
            let _clients = [];
            let _regions = [];
            let _groups = [];

            const clientSnapshots = await window.firebase.firestore()
                .collection(`clients`)
                .where('settingsID', '==', selectedOEM.id).get()
            clientSnapshots.docs.forEach(rec => {
                _clients.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const regionSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`regions`)
                .get()
            regionSnapshots.docs.forEach(rec => {
                _regions.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const groupSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`groups`)
                .get()
            groupSnapshots.docs.forEach(rec => {
                _groups.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })


            setClientOptions(_clients)
            setRegionOptions(_regions)
            setGroupOptions(_groups)
            setLoader(false)
        }
        fetchOptions()

    }, [selectedOEM])


    useEffect(() => {
        if (_.isEmpty(selectedOEM)) {
            return
        }

        const isLevelOEM = !selectedLevel || selectedLevel.level === 'oem' ? true : false

        if (!isLevelOEM && !selectedLevel.levelID) {
            return
        }

        let integrationRef
        if (isLevelOEM) {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id)
        } else if (selectedLevel.level === 'region') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('regions').doc(selectedLevel.levelID)
        } else if (selectedLevel.level === 'group') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('groups').doc(selectedLevel.levelID)
        } else if (selectedLevel.level === 'individual') {
            integrationRef = window.firebase.firestore().collection(`clients`).doc(selectedLevel.levelID)
        }

        const integrationsSnapshot = integrationRef
            .onSnapshot(querySnapshot => {
                if (querySnapshot.exists) {
                    const _data = querySnapshot.data()
                    if (!_.isEmpty(_data) && !_.isEmpty(_data.moduleSettings)) {
                        setModules(_data.moduleSettings)
                        setLoader(false)
                    } else {
                        setModules({})
                        setLoader(false)
                    }
                } else {
                    setModules({})
                    setLoader(false)
                }
            })

        return () => {
            integrationsSnapshot && integrationsSnapshot()
        }
    }, [selectedLevel, selectedOEM])

    const enableAddons = (module, checked) => {
        setBtnLoader({
            [module]: true
        })

        const isLevelOEM = !selectedLevel || selectedLevel.level === 'oem' ? true : false

        let integrationRef
        if (isLevelOEM) {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id)
        } else if (selectedLevel.level === 'region') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('regions').doc(selectedLevel.levelID)
        } else if (selectedLevel.level === 'group') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('groups').doc(selectedLevel.levelID)
        } else if (selectedLevel.level === 'individual') {
            integrationRef = window.firebase.firestore().collection(`clients`).doc(selectedLevel.levelID)
        }

        integrationRef
            .set({
                moduleSettings: {
                    ...modules,
                    [module]: module === 'autograb' ? {
                        ...modules[module],
                        enabled: checked,
                        active: checked,
                        vehicleDetail: checked
                    } : {
                        ...modules[module],
                        enabled: checked,
                        active: checked
                    }
                }
            }, { merge: true })
            .then(snapshot => {

                //update settings date and priority
                if (selectedLevel && selectedLevel.level === 'individual') {
                    window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'critical',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: null,
                        }, { merge: true })
                    //end

                    //if deactivate sales module remove sales module from role
                    if (module === 'sales' || module === 'service' || module === 'tradeinPro' || module === 'eventsPro') {
                        if (!checked) {
                            // window.firebase.firestore().collection('clientSettings').doc(selectedOEM.id).collection('roles')
                            //     .get()
                            //     .then(snap => {
                            //         snap.docs.forEach(doc => {
                            //             window.firebase.firestore().doc(`clientSettings/${selectedOEM.id}/roles/${doc.id}`).set({
                            //                 modules: module === 'sales' ? {
                            //                     ...doc.data().modules,
                            //                     enquiry: false,
                            //                     frontdesk: false,
                            //                     loanVehicle: false,
                            //                     oem: false,
                            //                     stock: false,
                            //                     kitchen: false
                            //                 } : module === 'service' ? {
                            //                     ...doc.data().modules,
                            //                     service: false
                            //                 } : module === 'eventsPro' ? {
                            //                     ...doc.data().modules,
                            //                     eventsPro: false
                            //                 } : {
                            //                     ...doc.data().modules,
                            //                     tradeinPro: false
                            //                 }
                            //             }, { merge: true });
                            //         })
                            //     })
                        }
                        else {
                            window.firebase.firestore().collection('clientSettings').doc(selectedOEM.id).collection('roles')
                                .where('name', '==', 'Administrator')
                                .get()
                                .then(snap => {
                                    snap.docs.forEach(doc => {
                                        window.firebase.firestore().doc(`clientSettings/${selectedOEM.id}/roles/${doc.id}`).set({
                                            modules: module === 'sales' ? {
                                                ...doc.data().modules,
                                                enquiry: true,
                                                frontdesk: true,
                                                loanVehicle: true,
                                                oem: true,
                                                stock: true,
                                                kitchen: true
                                            } : module === 'service' ? {
                                                ...doc.data().modules,
                                                service: true
                                            } : module === 'eventsPro' ? {
                                                ...doc.data().modules,
                                                eventsPro: true
                                            } : {
                                                ...doc.data().modules,
                                                tradeinPro: true
                                            }
                                        }, { merge: true });
                                    })
                                })
                        }
                    }
                    if (module === 'eventsPro' && checked) {
                        window.firebase.firestore().doc(`clientSettings/${selectedOEM.id}/eventsPro/${selectedOEM.id}`)
                            .get()
                            .then(snap => {
                                if (!snap.exists) {
                                    window.firebase.firestore().doc(`clientSettings/${selectedOEM.id}/eventsPro/${selectedOEM.id}`)
                                        .set({
                                            pipelines: eventspropipelineVM,
                                            categories: defaultEventCategories
                                        }, { merge: true })
                                }
                            })
                    }

                }

                toast.notify(`Add-on ${checked ? 'enabled' : 'disabled'} successfully.`, {
                    duration: 2000
                })
                setBtnLoader({})
            })
            .catch(error => {
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    return (<>
        <h2>Add-ons</h2>
        <div className='divider-line'></div>
        <div className="mt-4">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={oemOptions}
                                    name={"oem"}
                                    placeholder={'select oem'}
                                    onChange={(e) => {
                                        setLoader(true)
                                        setSelectedOEM(e.data)
                                    }}
                                    value={!_.isEmpty(selectedOEM) ? selectedOEM.id : ''}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={levelOptions}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={(e, data) => {
                                        if (e) {
                                            setSelectedLevel({
                                                level: e.value
                                            });
                                        }
                                        else
                                            setSelectedLevel(null);
                                    }}
                                    value={selectedLevel ? selectedLevel.level : 'oem'}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? (<div className="col-md-3 pr-0">
                                    <ReactSelect
                                        options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                        name={'levelID'}
                                        placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                        onChange={(e, data) => {
                                            if (e) {
                                                setLoader(true)
                                                setSelectedLevel({
                                                    ...selectedLevel,
                                                    levelID: e.value
                                                });
                                            }
                                            else
                                                setSelectedLevel(null);
                                        }}
                                        value={selectedLevel ? selectedLevel.levelID : ''}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (_.isEmpty(selectedOEM)) ? (
                <div className="common-table">
                    <div className="text-center p-5">
                        <p>Please select client</p>
                    </div>
                </div>
            ) : !loader && !_.isEmpty(selectedLevel) && ((!selectedLevel || selectedLevel.level === 'oem') || !_.isEmpty(selectedLevel.levelID)) ? (<div className="settings-integration-list">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.amsSales} /></div>
                                <div className="integration-title"><a href="#">AMS Sales</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.sales && modules.sales.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('sales', false) }}>
                                        {
                                            btnLoader.sales ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('sales', true) }}>
                                            {
                                                btnLoader.sales ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }

                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.tradeinplus} /></div>
                                <div className="integration-title"><a href="#">Trade-In Plus</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.tradeinPlus && modules.tradeinPlus.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('tradeinPlus', false) }}>
                                        {
                                            btnLoader.tradeinPlus ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('tradeinPlus', true) }}>
                                            {
                                                btnLoader.tradeinPlus ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }

                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.amsvideo} /></div>
                                <div className="integration-title"><a href="#">AMS Video</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.amsVideo && modules.amsVideo.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('amsVideo', false) }}>
                                        {
                                            btnLoader.amsVideo ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('amsVideo', true) }}>
                                            {
                                                btnLoader.amsVideo ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.amsfinance} /></div>
                                <div className="integration-title"><a href="#">AMS Finance</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.finance && modules.finance.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('finance', false) }}>
                                        {
                                            btnLoader.finance ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('finance', true) }}>
                                            {
                                                btnLoader.finance ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        {/* <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.regoscan} /></div>
                                <div className="integration-title"><a href="#">Reg Scan</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.regoScan && modules.regoScan.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('regoScan', false) }}>
                                        {
                                            btnLoader.regoScan ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('regoScan', true) }}>
                                            {
                                                btnLoader.regoScan ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div> */}
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.rego_search} /></div>
                                <div className="integration-title"><a href="#">Rego Search</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.autograb && modules.autograb.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('autograb', false) }}>
                                        {
                                            btnLoader.autograb ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('autograb', true) }}>
                                            {
                                                btnLoader.autograb ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>

                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.ppsr} /></div>
                                <div className="integration-title"><a href="#">PPSR</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.ppsr && modules.ppsr.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('ppsr', false) }}>
                                        {
                                            btnLoader.ppsr ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('ppsr', true) }}>
                                            {
                                                btnLoader.ppsr ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.licensescan} /></div>
                                <div className="integration-title"><a href="#">License Scan</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.licenseScan && modules.licenseScan.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('licenseScan', false) }}>
                                        {
                                            btnLoader.licenseScan ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('licenseScan', true) }}>
                                            {
                                                btnLoader.licenseScan ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.amsfrontdesk} /></div>
                                <div className="integration-title"><a href="#">Front Desk</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.frontdesk && modules.frontdesk.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('frontdesk', false) }}>
                                        {
                                            btnLoader.frontdesk ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('frontdesk', true) }}>
                                            {
                                                btnLoader.frontdesk ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>

                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.amsService} /></div>
                                <div className="integration-title"><a href="#">Service</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.service && modules.service.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('service', false) }}>
                                        {
                                            btnLoader.service ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('service', true) }}>
                                            {
                                                btnLoader.service ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.tradeinPro} /></div>
                                <div className="integration-title"><a href="#">Trade-In Pro</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.tradeinPro && modules.tradeinPro.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('tradeinPro', false) }}>
                                        {
                                            btnLoader.tradeinPro ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('tradeinPro', true) }}>
                                            {
                                                btnLoader.tradeinPro ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.eventsPro} /></div>
                                <div className="integration-title"><a href="#">Events Pro</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.eventsPro && modules.eventsPro.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('eventsPro', false) }}>
                                        {
                                            btnLoader.eventsPro ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('eventsPro', true) }}>
                                            {
                                                btnLoader.eventsPro ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        {/* <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.afterSales} /></div>
                                <div className="integration-title"><a href="#">AMS After Sales</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.afterSales && modules.afterSales.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('afterSales', false) }}>
                                        {
                                            btnLoader.afterSales ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('afterSales', true) }}>
                                            {
                                                btnLoader.afterSales ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div> */}
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.cafe} /></div>
                                <div className="integration-title"><a href="#">AMS Cafe</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.cafe && modules.cafe.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('cafe', false) }}>
                                        {
                                            btnLoader.cafe ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('cafe', true) }}>
                                            {
                                                btnLoader.cafe ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.fusionevents} /></div>
                                <div className="integration-title"><a href="#">Fusion Events</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.fusionevents && modules.fusionevents.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('fusionevents', false) }}>
                                        {
                                            btnLoader.fusionevents ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('fusionevents', true) }}>
                                            {
                                                btnLoader.fusionevents ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.tvModule} /></div>
                                <div className="integration-title"><a href="#">TV Display</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.tvModule && modules.tvModule.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('tvModule', false) }}>
                                        {
                                            btnLoader.tvModule ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('tvModule', true) }}>
                                            {
                                                btnLoader.tvModule ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.performanceReview} /></div>
                                <div className="integration-title"><a href="#">Daily Performance Review</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.performanceReview && modules.performanceReview.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('performanceReview', false) }}>
                                        {
                                            btnLoader.performanceReview ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('performanceReview', true) }}>
                                            {
                                                btnLoader.performanceReview ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.roster} /></div>
                                <div className="integration-title"><a href="#">Roster</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.roster && modules.roster.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('roster', false) }}>
                                        {
                                            btnLoader.roster ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('roster', true) }}>
                                            {
                                                btnLoader.roster ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.invoice} /></div>
                                <div className="integration-title"><a href="#">Service Invoice</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.serviceInvoice && modules.serviceInvoice.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('serviceInvoice', false) }}>
                                        {
                                            btnLoader.serviceInvoice ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('serviceInvoice', true) }}>
                                            {
                                                btnLoader.serviceInvoice ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.amsoem} /></div>
                                <div className="integration-title"><a href="#">OEM</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.oem && modules.oem.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('amsOEM', false) }}>
                                        {
                                            btnLoader.oem ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('oem', true) }}>
                                            {
                                                btnLoader.oem ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.emailCampaign} /></div>
                                <div className="integration-title"><a href="#">Email Campaign</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.mailjet && modules.mailjet.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('mailjet', false) }}>
                                        {
                                            btnLoader.mailjet ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('mailjet', true) }}>
                                            {
                                                btnLoader.mailjet ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.fleetPro} /></div>
                                <div className="integration-title"><a href="#">Fleet Management</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.fleetPro && modules.fleetPro.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('fleetPro', false) }}>
                                        {
                                            btnLoader.fleetPro ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('fleetPro', true) }}>
                                            {
                                                btnLoader.fleetPro ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.serviceFleetPro} /></div>
                                <div className="integration-title"><a href="#">Service Fleet Pro</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.serviceFleetPro && modules.serviceFleetPro.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('serviceFleetPro', false) }}>
                                        {
                                            btnLoader.serviceFleetPro ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('serviceFleetPro', true) }}>
                                            {
                                                btnLoader.serviceFleetPro ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                        <div className="col-md-4 margin-btm-30">
                            <div className="integration-box">
                                <div className="integration-icon"><img alt="" src={images.deallog} /></div>
                                <div className="integration-title"><a href="#">AMS Deal Log</a></div>
                                <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p>
                                {
                                    modules && modules.dealLog && modules.dealLog.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('dealLog', false) }}>
                                        {
                                            btnLoader.dealLog ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        Deactivate </button>)
                                        : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('dealLog', true) }}>
                                            {
                                                btnLoader.dealLog ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Activate </button>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ) : (
                <div style={{ height: '55vh' }}>
                    <div className='spinner-loader h-100'>
                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                            <div className="no-data-txt mt-2">
                                <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    </>);
}

export default ModuleAddOns;