import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

const PieChart = (props) => {

    const [chartData, setChart] = useState(null)

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        if (JSON.stringify(chartData.data) !== JSON.stringify(props.data))
            chartData.data = props.data
        am4core.array.each(chartData.data, (item) => {
            if (_.find(props.data, { value: item.value }))
                item.count = _.find(props.data, { value: item.value }).count
        })

        chartData.invalidateRawData();

    }, [props.data])

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        chartData.series.clear()
        chartData.invalidateData();
        createSeries(chartData);


    }, [props.columns])

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        props.setChartData && props.setChartData(chartData)
    }, [chartData])


    useEffect(() => {
        let chart = am4core.create(props.id, am4charts.PieChart);
        chart.logo.disabled = true;
        chart.paddingRight = 20;
        chart.legend = new am4charts.Legend();
        chart.legend.position = props.legendPosition ? props.legendPosition : "right";
        if (props.legendPosition === 'bottom') {
            //chart.legend.height = 50;
            chart.legend.maxHeight = 100;
        }
        chart.legend.scrollable = true;
        chart.legend.maxWidth = 200
        if (props.columns === 'show')
            chart.legend.valueLabels.template.text = `{value.percent.formatNumber('#.')}% ({showCount})`;
        else
            chart.legend.valueLabels.template.text = `{value.percent.formatNumber('#.')}% ({${props.columns ? props.columns : 'count'}})`;
        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.data = props.data;
        chart.innerRadius = am4core.percent(40);
        chart.legend.disabled = Boolean(props.disableLegend)
        if (Boolean(props.semiChart)) {
            chart.startAngle = 180;
            chart.endAngle = 360;
        }

        createSeries(chart)
        const piechart = chart;
        setChart(chart)
        return () => {
            piechart && piechart.dispose()
        }
    }, [])

    const createSeries = (chart) => {
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = props.columns ? props.columns : "count";
        pieSeries.dataFields.category = "name";
        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.')}%";
        pieSeries.labels.template.radius = am4core.percent(-30);
        pieSeries.labels.template.fill = am4core.color("white");
        if (props.columns === 'show')
            pieSeries.slices.template.tooltipText = `{name}: {value.percent.formatNumber('#.')}% ({showCount})`;
        else
            pieSeries.slices.template.tooltipText = `{name}: {value.percent.formatNumber('#.')}% ({${props.columns ? props.columns : 'count'}})`;

        pieSeries.slices.template.events.on("doublehit", function (ev) {
            if (props.dynamicReport === true)
                props.handleShowEnquiryList(ev.target.dataItem.component.dataFields.value, ev.target.dataItem.dataContext);
            else
                props.handleShowEnquiryList(ev.target.dataItem.dataContext.value, ev.target.dataItem.dataContext.startDate, ev.target.dataItem.dataContext.endDate, ev.target.dataItem.dataContext)
        }, this);
        pieSeries.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0;
            }
            return radius;
        });

        pieSeries.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return am4core.color("#000");
            }
            return color;
        });

        pieSeries.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        pieSeries.labels.template.events.on("ready", hideSmall);
        pieSeries.labels.template.events.on("visibilitychanged", hideSmall);

        function hideSmall(ev) {
            if (ev.target.dataItem && (ev.target.dataItem.values.value.percent < 5)) {
                ev.target.hide();
            }
            else {
                ev.target.show();
            }
        }

        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        // Set mouse style on hover
        pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    }

    return (
        <div id={props.id} style={{ width: props.width || "100%", height: props.height }}></div>
    );
}

export default PieChart;