import React, { useCallback, useContext, useEffect, useState } from 'react';
import { firestoreDB } from '../../services/helper';
import _ from 'lodash';
import moment from 'moment';
import { allenquiryFields, tagColors, revenueTargets } from './viewModel';
import common from '../../services/common';
import NumberFormat from 'react-number-format';
import images from '../../images';
import CommonHelper from '../../services/common';
import { AntMonthPicker, DropDownMenu, GridView, PopUpModal, SidePanelDetailView } from '../../components';
import ChangeLogs from './changeLogs';
import FieldComments from './fieldComments';
import { StageProvider, StageConsumer } from '../pipeline/provider'
import QuickView from "../pipeline/quickview";
import { MainContext } from '../../views/layout/provider';
import EnquiryInfo from '../pipeline/details';
import { RouteConsumer } from '../layout/RouteProvider';
import CellColorSettings from './cellColor';
import RevenueTarget from './revenueTarget';

const DealsLog = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 310) })
    const [wonLeads, setWonLeads] = useState([])
    const [carryoverLeads, setCarryoverLeads] = useState([])
    const [coveredLeads, setCoveredLeads] = useState([])
    const [deliveredLeads, setDeliveredLeads] = useState([])
    const [reportedLeads, setReportedLeads] = useState([])
    const [reportableLeads, setReportableLeads] = useState([])
    const [remainingLeads, setRemainingLeads] = useState([])
    const [enquiries, setEnquiries] = useState([])
    const [allEnquiries, setAllEnquiries] = useState([])
    const [loader, setLoader] = useState(true)
    const [dLOrderFields, setdLOrderFields] = useState();
    const [afterSoldStatuses, setafterSoldStatuses] = useState(props?.dealersettings?.client?.settings?.enquiryStatus?.filter(e => e.afterSold === true && e.active === true)?.map(r => { return r.value }) || [])
    const [gridRefresh, setGridRefresh] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [selecteddepartment, setSelectedDepartments] = useState(null);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate()),
        date: moment().toDate(),
        year: moment().format('YYYY'),
        month: moment().format('MMM')
    })
    const [dynamicCols, setDynamicCols] = useState()
    const [currentSettings, setCurrentSettings] = useState()
    const [deallogSettings, setdeallogSettings] = useState()
    const [changelogmodal, setchangelogmodal] = useState({ show: false })
    const [notesmodal, setnotesmodal] = useState({ show: false })
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview-detail' })
    const [dataRefresh, setDataRefresh] = useState(false)
    const { dialRingcentral, dialNumber, updateDealerSettings, clientModule, dealersettings, clientUsers, groupUsers, currentClient, history } = useContext(MainContext);
    const [colormodal, setcolormodal] = useState({ show: false })


    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 310)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.enquiriesCollectionzz && window.enquiriesCollectionzz.unsubscribe()
        }
    }, [])

    const sidepanelClose = async () => {
        if (showpanel.enquiryid) {
            const enq = await firestoreDB(props.dealersettings).firestore().doc(`enquiries/${showpanel.enquiryid}`).get();
            if (enq.exists) {
                updateListEnquiry({
                    ...enq.data(),
                    documentID: enq.id,
                    sno: showpanel?.colParams?.data?.sno || null
                })
            }
        }
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0,
            colParams: null
        })
    }

    const sidepanelOpen = (val, params) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val,
            colParams: params
        })
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            const updateData = common.convertSalesLogVM({ ...objEnquiry, id: objEnquiry.documentID }, props, deallogSettings);
            if (showpanel?.colParams) {
                showpanel.colParams.api.getRowNode(showpanel.colParams.node.rowIndex).updateData(updateData)
                // showpanel.colParams.api.redrawRows({
                //     rowNodes: [props.colParams.node],
                //     columns: [props.colParams.column.colId],
                // });
            }

        }

    }

    useEffect(() => {
        const saletypes = props?.dealersettings?.client?.settings?.salesType?.filter(e => e.active === true) || []
        let _dept = [];
        window.firebase
            .firestore()
            .doc(`/clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`)
            .get()
            .then(snap => {
                setdeallogSettings(snap?.data() || {});
                if (snap?.data()?.departments?.length) {
                    _dept = snap.data().departments.map(r => {
                        return {
                            ...r,
                            label: r.name,
                            value: r.id
                        }
                    });
                } else {
                    const dealerList = CommonHelper.getDealLogDealers(props.dealersettings, true, true);
                    dealerList.forEach(rec => {
                        saletypes.forEach(st => {
                            _dept.push({
                                label: `${rec.name} - ${st.name}`,
                                clientID: rec.id,
                                saleTypes: [st.value],
                                value: `${rec.id}-${st.value}`
                            })
                        })
                    })
                }
                setDepartments(_dept);
                setSelectedDepartments(_dept[0]);
            })
    }, [])

    useEffect(() => {
        window.firebase
            .firestore()
            .doc(`users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .get()
            .then(snap => {
                if (snap?.data()?.dLOrderFields?.length) {
                    setdLOrderFields(snap.data().dLOrderFields.map(r => { return { ...r, default: true } }))
                } else {
                    setdLOrderFields(allenquiryFields.filter(a => a.default === true).map(r => { return { name: r.name, value: r.value, default: true } }))
                }
            })
    }, [])



    useEffect(() => {
        if (_.isEmpty(dLOrderFields) || _.isEmpty(selecteddepartment?.clientID))
            return
        const currentRole = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/roles/${props.dealersettings.roleID}`).get()
        const currentSettings = window.firebase.firestore().doc(`clients/${selecteddepartment?.clientID}/currentSettings/${selecteddepartment?.clientID}`).get()

        Promise.all([currentRole, currentSettings])
            .then(snaps => {
                let _settings = props?.dealersettings?.client?.settings;
                let _role = props?.dealersettings?.rolePermissions
                if (snaps[1]?.data()?.settings) {
                    _settings = snaps[1].data().settings;
                }
                if (snaps[0]?.data()) {
                    _role = snaps[0]?.data();
                }
                //console.log(_role)
                const enquiryFields = allenquiryFields
                    .filter(a => !Boolean(a.dynamic))
                    .map(a => {
                        return {
                            ...a,
                            name: _.find(_settings?.enquiryOptionsStatic, { value: a.fieldDB })?.name || a.name,
                            format: a.picker === 'month' ? a.format : deallogSettings?.dateFormat
                        }
                    });
                const settings = loadSettings(_settings);
                settings?.enquiryOptionsDF?.filter(a => a.active === true)?.forEach(rec => {
                    enquiryFields.push({
                        name: rec.name,
                        value: rec.type === 'price' || rec.type === 'number' ? rec.value : `str${rec.value}`,
                        editable: true,
                        dynamic: true,
                        dataType: rec.type,
                        fieldDB: rec.value,
                        settingsField: rec.value,
                        isMulti: rec.type === 'multiselect',
                        nestedObj: 'dynamicFields',
                        format: deallogSettings?.dateFormat,
                        collections: [
                            { name: 'enquiries', nestedObj: 'dynamicFields' }
                        ]
                    })
                })
                setCurrentSettings(settings);
                let _dynCols = [
                    ...dLOrderFields.filter(a => enquiryFields.some(b => b.value === a.value)).map(r => { return { ...r, ..._.find(enquiryFields, { value: r.value }) } }),
                    ...enquiryFields.filter(a => !dLOrderFields.some(b => b.value === a.value)).map(r => { return { ...r, default: false } })
                ];
                _dynCols = _dynCols.filter(a => _role?.deallogPermissions?.[a.value] !== 'hide').map(r => {
                    return {
                        ...r,
                        editable: r.editable ? (_.isEmpty(_role?.deallogPermissions?.[r.value]) || _role?.deallogPermissions?.[r.value] === 'edit') : false,
                        //cellStyle: params => { return loadCellColor(params) },
                    }
                })
                setDynamicCols(_dynCols);
            });

    }, [dLOrderFields, selecteddepartment?.clientID, deallogSettings?.fieldColors])

    const loadCellColor = (params) => {
        let __color = null;
        if (deallogSettings?.fieldColors?.[params.column.colId]?.length && params?.data?.[params?.column?.colDef?.fieldDB]) {
            let __isValid = null;
            deallogSettings.fieldColors[params.column.colId].forEach(rec => {
                switch (rec.option) {
                    case '<':
                        __isValid = moment.unix(params?.data?.[params?.column?.colDef?.fieldDB]?.seconds).isBefore(moment().startOf('month'));
                        break;
                    case '>':
                        __isValid = moment.unix(params?.data?.[params?.column?.colDef?.fieldDB]?.seconds).isAfter(moment().endOf('month'));
                        break;
                    case '=':
                        __isValid = moment.unix(params?.data?.[params?.column?.colDef?.fieldDB]?.seconds).month() === moment().month();
                        break;
                }
                if (__isValid) {
                    __color = rec.color;
                }
            })
        }
        return __color ? { color: __color } : {};
    }

    const loadSettings = (_settings) => {
        const settings = _settings;
        const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
        settings.allUsers = _.uniqBy(allUsers.filter(a => a.clientID === selecteddepartment?.clientID), 'id');
        settings?.enquiryOptionsDF?.filter(a => a.type === 'select' || a.type === 'multiselect')?.forEach(rec => {
            settings[rec.value] = rec?.subList?.map(r => { return { name: r, value: r, active: true } })
        })
        settings.makes = props?.dealersettings?.allMakeModels || [];
        props?.dealersettings?.allMakeModels?.forEach(make => {
            settings[`${make.value}models`] = make?.models?.map(r => { return { name: r.value, value: r.value } }) || []
            settings[`${make.value}types`] = make?.models?.filter(a => a.type)?.map(r => { return { name: r.type, value: r.type } }) || []
            settings[`${make.value}groups`] = make?.models?.filter(a => a.group)?.map(r => { return { name: r.group, value: r.group } }) || []
        })
        const years = [];
        for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
            years.push({
                value: i.toString(),
                name: i.toString(),
                active: true
            });
        }
        settings.years = years;
        const financeroleIds = props?.dealersettings?.client?.roles?.filter(e => e.permissions && e.permissions.accessToBusinessManager === true)?.map(rec => { return rec.id }) || [];
        const aftermeketroleIds = props?.dealersettings?.client?.roles?.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true)?.map(rec => { return rec.id }) || [];
        settings.financeManagers = settings.allUsers?.filter(a => Boolean(a.superAdmin) === false && Boolean(a.active) && (financeroleIds.length > 0 ? _.includes(financeroleIds, a.roleID) : false))?.map(rec => {
            return {
                value: rec.id,
                searchlabel: rec.name,
                name: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            }
        })
        settings.afterMarketManagers = settings.allUsers?.filter(a => Boolean(a.superAdmin) === false && Boolean(a.active) && (aftermeketroleIds.length > 0 ? _.includes(aftermeketroleIds, a.roleID) : false))?.map(rec => {
            return {
                value: rec.id,
                searchlabel: rec.name,
                name: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            }
        })
        settings.clientUsers = settings.allUsers?.filter(a => Boolean(a.superAdmin) === false && Boolean(a.active))?.map(rec => {
            return {
                value: rec.id,
                searchlabel: rec.name,
                name: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            }
        })
        settings.enqLabels = settings?.enquiryLabels?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: CommonHelper.bindEnquiryLabel(settings?.enquiryLabels, rec.value, rec.name)
            }
        })
        settings.financeStatuses = settings?.financeStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.financeStatus}
                    className={`status-${rec.financeStatus === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.afterMarketStatuses = settings?.afterMarketStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.afterMarketStatus}
                    className={`status-${rec.afterMarketStatus === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.enquiryStatuses = settings?.enquiryStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.status}
                    className={`status-${rec.status === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.nationalities = props?.dealersettings?.nationalities || [];
        settings.languages = props?.dealersettings?.languages?.map(r => { return { ...r, value: r.code } }) || [];
        settings.countries = props?.dealersettings?.countries?.map(r => { return { ...r, value: r.code } }) || [];
        settings.countries?.forEach(country => {
            settings[`${country.code}states`] = props?.dealersettings?.states?.filter(a => a.country_code === country.code)?.map(r => { return { ...r, value: r.code } }) || []
        })
        settings.opts = [
            { active: true, value: true, name: 'Opt In' },
            { active: true, value: false, name: 'Opt Out' }
        ]
        return settings;
    }

    useEffect(() => {
        if (!selecteddepartment?.clientID) {
            return
        }
        //current month sold
        setLoader(true)
        const refSoldData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', selecteddepartment.clientID)
            .where('status', 'in', ['won', ...afterSoldStatuses])
            .where('isDeleted', '==', false)
            .where('wonDate', '>=', dateRange.startDate)
            .where('wonDate', '<=', dateRange.endDate)
            .get()

        //anything marked as won previous month and not delivered
        const refCarryoverData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', selecteddepartment.clientID)
            .where('status', 'in', ['won', ...afterSoldStatuses])
            .where('isDeleted', '==', false)
            .where('wonDate', '<', dateRange.startDate)
            .get()

        //current month delivered
        const refDeliveredData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', selecteddepartment.clientID)
            .where('status', 'in', ['delivered'])
            .where('isDeleted', '==', false)
            .where('deliveredDate', '>=', dateRange.startDate)
            .where('deliveredDate', '<=', dateRange.endDate)
            .get()


        Promise.all([refSoldData, refCarryoverData, refDeliveredData])
            .then(snaps => {
                const _wonLeads = _.map(snaps[0].docs, function (obj) { return common.convertSalesLogVM({ ...obj.data(), documentID: obj.id, id: obj.id }, props, deallogSettings) })
                const _carryoverLeads = _.map(snaps[1].docs, function (obj) { return common.convertSalesLogVM({ ...obj.data(), documentID: obj.id, id: obj.id }, props, deallogSettings) })
                const _deliveredLeads = _.map(snaps[2].docs, function (obj) { return common.convertSalesLogVM({ ...obj.data(), documentID: obj.id, id: obj.id }, props, deallogSettings) })

                setGridRefresh(true)
                setAllEnquiries([
                    ..._wonLeads.filter(a => a.pipeline !== 'LeadsBucket'),
                    ..._carryoverLeads.filter(a => a.pipeline !== 'LeadsBucket'),
                    ..._deliveredLeads.filter(a => a.pipeline !== 'LeadsBucket')
                ])
                setLoader(false)
                setDataRefresh(true)
            })
    }, [selecteddepartment?.clientID, dateRange])

    useEffect(() => {
        if (!dataRefresh)
            return

        const status = selecteddepartment?.filterStatus || 'other';
        const wonStatus = ['won', ...afterSoldStatuses];
        const filterEnquiries = allEnquiries.filter(({ saleType, make }) =>
            (selecteddepartment?.saleTypes?.length ? selecteddepartment.saleTypes.includes(saleType) : true) &&
            (selecteddepartment?.makes?.length ? selecteddepartment.makes.includes(make) : true)
        ).map(rec => {
            return {
                ...rec,
                revenueAmount: revenueCalc(rec)
            }
        });
        const allLeads = {
            wonLeads: filterEnquiries
                .filter(a => wonStatus.includes(a.status) && a.wonDate?.seconds >= dateRange.startDate.seconds && a.wonDate?.seconds <= dateRange.endDate.seconds),
            carryoverLeads: filterEnquiries.filter(a => wonStatus.includes(a.status) && a.wonDate?.seconds < dateRange.startDate.seconds),
            deliveredLeads: filterEnquiries.filter(a => a.status === 'delivered' && a.deliveryDate?.seconds >= dateRange.startDate.seconds && a.deliveryDate?.seconds <= dateRange.endDate.seconds),
            coveredLeads: filterEnquiries.filter(a => wonStatus.includes(a.status) && a.deliveryDate?.seconds >= dateRange.startDate.seconds && a.deliveryDate?.seconds <= dateRange.endDate.seconds)
        }
        //allLeads.wonLeads = _.orderBy(allLeads.wonLeads, 'wonDate.seconds', 'desc').map((r, i) => { return { ...r, sno: (allLeads.wonLeads.length - i) } })
        allLeads.otherLeads = _.uniqBy(allLeads.wonLeads.concat(allLeads.carryoverLeads), 'id');

        if (deallogSettings?.reportedCalc?.field) {
            allLeads.reportableLeads = filterEnquiries.filter(a => wonStatus.includes(a.status)
                && a.deliveryDate?.seconds >= dateRange.startDate.seconds
                && a.deliveryDate?.seconds <= dateRange.endDate.seconds
                && (deallogSettings?.reportedCalc?.excludeSaleType?.length ? !deallogSettings.reportedCalc.excludeSaleType.includes(a.saleType) : true)
            );
            allLeads.reportedLeads = allLeads.reportableLeads.filter(a => !_.isEmpty(a[`str${deallogSettings?.reportedCalc?.field}`]));
            allLeads.remainingLeads = allLeads.reportableLeads.filter(a => !allLeads.reportedLeads.some(b => b.id === a.id));
        }


        setWonLeads(_.uniqBy(allLeads.wonLeads, 'id'));
        setCarryoverLeads(_.uniqBy(allLeads.carryoverLeads, 'id'));
        setDeliveredLeads(_.uniqBy(allLeads.deliveredLeads, 'id'));
        setCoveredLeads(_.uniqBy(allLeads.coveredLeads, 'id'));
        setReportableLeads(_.uniqBy(allLeads.reportableLeads, 'id'));
        setReportedLeads(_.uniqBy(allLeads.reportedLeads, 'id'));
        setRemainingLeads(_.uniqBy(allLeads.remainingLeads, 'id'))
        if (gridRefresh) {
            setEnquiries(_.orderBy(allLeads[`${status}Leads`], 'wonDate.seconds', 'desc').map((r, i) => {
                return {
                    ...r,
                    sno: (allLeads.wonLeads.length - i) > 0 && moment(dateRange.date)?.month() === moment.unix(r?.wonDate?.seconds)?.month() ? (allLeads.wonLeads.length - i) : null
                }
            }));
            setGridRefresh(false)
        }
        setDataRefresh(false)

    }, [dataRefresh]);

    const revenueCalc = (data) => {
        //revenue calculation
        let _amount = 0;
        if (deallogSettings?.revenueCalc?.filter(a => a.value)?.length) {
            deallogSettings?.revenueCalc.filter(a => a.value).forEach(r => {
                if (r.action === 'add')
                    _amount += data[r.value] || 0
                else
                    _amount -= data[r.value] || 0
            })
        } else {
            _amount = data.grossAmount || 0;
        }
        return _amount;
    }

    const saveSettings = useCallback((params) => {
        const fields = params.api.getColumnDefs().filter(a => !Boolean(a.hide) && a.value !== "#").map(r => { return _.pick(r, ['name', 'value', 'width', 'pinned']) });
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ ['dLOrderFields']: fields }, { merge: true })
            .catch(error => {
                console.error(error)
            });
    }, [])

    const onGridValueChange = useCallback(async (params) => {
        if (params?.colDef?.collections) {
            for (const rec of params?.colDef?.collections) {
                const docID = rec.docID ? (params?.data?.[rec.docID] || params?.data?.documentID) : params?.data?.documentID;
                let collectionDoc = rec.name && docID ? `${rec.name}/${docID}` : null;
                if (rec.subcollection) {
                    collectionDoc = params?.data?.[rec.collectionID] ? `${rec.collection}/${params.data[rec.collectionID]}/${collectionDoc}` : null;
                }
                const value = _.isUndefined(params?.data?.[params?.colDef?.fieldDB]) ? null : params?.data?.[params?.colDef?.fieldDB];
                const objUpdate = {};
                let field = params?.colDef?.fieldDB || null;
                if (field) {
                    if (params?.data?.isStock && rec?.nestedObj) {
                        objUpdate[`${rec.nestedObj}.stock.${field}`] = value;
                    } else if (params?.data?.isStock) {
                        objUpdate[`stock.${field}`] = value;
                    } else if (rec?.nestedObj) {
                        objUpdate[`${rec.nestedObj}.${field}`] = value;
                    } else {
                        objUpdate[field] = value;
                    }
                }
                console.log(collectionDoc, objUpdate)
                if (objUpdate && collectionDoc) {
                    await firestoreDB(props.dealersettings).firestore().doc(collectionDoc).update(objUpdate);
                }
            }
        }
        setAllEnquiries((values) => values.map(rec => { return rec.documentID === params?.data?.documentID ? params.data : rec }));
        setGridRefresh(false)
        setDataRefresh(true)
    }, [])

    const onContextMenuClick = useCallback((params, type) => {
        if (type === 'comments') {
            const cellPosition = getcellPosition(params?.eGridCell?.getBoundingClientRect())
            setnotesmodal({
                show: true,
                title: 'Comments',
                position: cellPosition > 100 ? 'right' : 'left',
                icon: "ico icon-message mailnotifiy-pop-title",
                data: params.node.data,
                params: params
            })
        } else if (type === 'changeLogs') {
            setchangelogmodal({
                show: true,
                title: 'Change Logs',
                recordIDs: [params?.node?.data?.contactID, params?.node?.data?.documentID],
                params: params
            })
        } else if (type === 'color') {
            const cellPosition = getcellPosition(params?.eGridCell?.getBoundingClientRect())
            setcolormodal({
                show: true,
                title: 'Color Cell',
                params: params,
                position: cellPosition > 100 ? 'right' : 'left',
                data: params.node.data,
            })
        } else if (type === 'cellTag') {
            tagCellColor(params);
        }
    }, [])

    const tagCellColor = useCallback((params) => {
        if (params?.node?.data?.documentID && params?.column?.colId) {
            const _tagColor = params.fieldColor || null;
            window.firebase.firestore().doc(`/enquiries/${params.node.data.documentID}`)
                .update({ [`fieldTags.${params.column.colId}`]: _tagColor })
                .then(() => {
                    params.api.getRowNode(params.node.rowIndex).updateData({
                        ...params.node.data,
                        fieldTags: params.node.data.fieldTags ? {
                            ...params.node.data.fieldTags,
                            [params.column.colId]: _tagColor
                        } : { [params.column.colId]: _tagColor }
                    })
                    params.api.redrawRows({
                        rowNodes: [params.node],
                        columns: [params.column.colId],
                    });
                })
                .catch(error => {
                    console.error(error)
                });
        }
    }, [])

    const getcellPosition = (rec) => {
        const windowWidth = window.innerWidth;
        const cellRight = rec?.right || 0;
        const popoverWidth = 450;

        return windowWidth - cellRight - popoverWidth;
    }

    const onCellClicked = useCallback((params) => {
        if (params?.column.colDef.value === 'sno' && params.data.documentID) {
            sidepanelOpen(params.data.documentID, params)
        }
    }, [])

    const onMsgIconClicked = useCallback((params) => {
        if (params?.data?.fieldComments?.[params?.column?.colId]) {
            const cellPosition = getcellPosition(params?.eGridCell?.getBoundingClientRect())
            setnotesmodal({
                show: true,
                position: cellPosition > 100 ? 'right' : 'left',
                title: 'Comments',
                icon: "ico icon-message mailnotifiy-pop-title",
                data: params.data,
                params
            })
        }
    }, [])

    const onDeptChange = (val) => {
        setSelectedDepartments(_.find(departments, { value: val }));
        setGridRefresh(true)
        setDataRefresh(true)
    }

    const onSortChanged = useCallback((params) => {

    }, [])

    return (<div className={`middle-wrapper h-100`}>
        <div className="dynamic-module-container">
            <div className="deallog-header-filterbar">
                <div className="deallog-filter-loop">
                    Departments
                    <div className={`badge badge-pill badge-open ml-2`} style={{ background: 'rgb(255, 255, 255)' }}>
                        <DropDownMenu
                            text={selecteddepartment?.label}
                            keyVal="100898"
                            tIndex="-1"
                            menu={departments}
                            handleChange={onDeptChange}
                        >
                        </DropDownMenu>
                    </div>
                </div>
                <div className='float-right'>
                    <AntMonthPicker
                        value={dateRange.date}
                        name={'deallog-month'}
                        onChange={(val) => {
                            if (val) {
                                setDateRange({
                                    year: moment(val).format('YYYY'),
                                    month: moment(val).format('MMM'),
                                    date: val,
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month').toDate())
                                })
                                setSelectedDepartments({
                                    ...selecteddepartment,
                                    filterStatus: null
                                })
                            }

                        }}
                        placeholder={'select month'}
                        className={`form-control cursor-pointer`}
                        format={'MMM YYYY'}
                        picker={'month'}
                        removeClear={true}
                    />
                </div>
            </div>

            <div className="dynamic-module-count-wrapper mt-2">
                {
                    revenueTargets.map((rec, index) => {
                        return <RevenueTarget
                            key={index}
                            data={rec}
                            carryoverLeads={carryoverLeads}
                            wonLeads={wonLeads}
                            deliveredLeads={deliveredLeads}
                            coveredLeads={coveredLeads}
                            currencySymbol={currencySymbol}
                            remainingLeads={remainingLeads}
                            reportableLeads={reportableLeads}
                            reportedLeads={reportedLeads}
                            targetClick={(sts) => {
                                setGridRefresh(true)
                                setSelectedDepartments({
                                    ...selecteddepartment,
                                    filterStatus: sts
                                })
                                setDataRefresh(true)
                            }}
                            target={selecteddepartment?.['targets']?.[dateRange.year]?.[dateRange.month]?.[rec.value]}
                        />
                    })
                }

            </div>
            <div className="dynamic-module-list-table">
                <div className="common-table">
                    <GridView
                        {...windowSize}
                        dealersettings={props.dealersettings}
                        dynamicCols={dynamicCols}
                        onGridValueChange={onGridValueChange}
                        saveSettings={saveSettings}
                        currencySymbol={currencySymbol}
                        currentSettings={{
                            ...currentSettings,
                            ...deallogSettings
                        }}
                        rowData={enquiries}
                        loader={loader}
                        sideBar={true}
                        dateFormat={deallogSettings?.dateFormat}
                        onContextMenuClick={onContextMenuClick}
                        onCellClicked={onCellClicked}
                        onMsgIconClicked={onMsgIconClicked}
                        excelfileName={'Orders'}
                        tagColors={tagColors}
                        minCellWidth={120}
                        onSortChanged={onSortChanged}
                    />
                </div>
            </div>
        </div>
        {
            selecteddepartment?.filterStatus ? (
                <span className="custom-deallog-toaster-top">
                    <div className="custom-deallog-toaster-message" style={{ opacity: 1 }}>
                        <div className="custom-deallog-toaster-alert">
                            <div className="flex-deallog-toast-custom">
                                <div className="custom-deallog-toast-icon">
                                    <img src={images.filtergreen} height="25" /> </div>
                                <div className="custom-deallog-toast-data">
                                    <div className="custom-deallog-toast-item-title">Filter Applied</div>
                                </div>
                                <button className="custom-deallog-toaster-alert-close" onClick={() => {
                                    setGridRefresh(true)
                                    setSelectedDepartments({
                                        ...selecteddepartment,
                                        filterStatus: null
                                    })
                                    setDataRefresh(true)
                                }}><span aria-hidden="true">×</span></button>
                            </div>
                        </div>
                    </div>
                </span>
            ) : (<></>)
        }
        <PopUpModal show={changelogmodal.show}>
            <ChangeLogs
                {...props}
                {...changelogmodal}
                handleClose={() => {
                    setchangelogmodal({ show: false })
                }}
                dealersettings={currentSettings}
                deallogSettings={deallogSettings}
                currencySymbol={currencySymbol}
            />
        </PopUpModal>
        <PopUpModal show={notesmodal.show}>
            <FieldComments
                {...notesmodal}
                dealersettings={currentSettings}
                handleClose={() => {
                    setnotesmodal({ show: false })
                }}

            />
        </PopUpModal>
        <PopUpModal show={colormodal.show}>
            <CellColorSettings
                {...colormodal}
                dealersettings={props.dealersettings}
                deallogSettings={deallogSettings}
                handleClose={() => {
                    setcolormodal({ show: false })
                }}

            />
        </PopUpModal>
        {/* <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
            <StageConsumer>
                {() => ( */}
        <RouteConsumer>
            {({ handleRouteRemove, resetnavbar, handleRouteChange, updatenavbar }) => (
                <SidePanelDetailView
                    clsActive={showpanel.clsActive}
                    clsName={showpanel.clsName}
                    sidepanelClose={sidepanelClose}
                    title=''
                >
                    <EnquiryInfo
                        enquiryid={showpanel.enquiryid}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        currentClient={currentClient}
                        resetnavbar={resetnavbar}
                        history={history}
                        updatenavbar={updatenavbar}
                        handleRouteRemove={handleRouteRemove}
                        updateDealerSettings={updateDealerSettings}
                        clientModule={clientModule}
                        dialNumber={dialNumber}
                        dialRingcentral={dialRingcentral}
                        handleRouteChange={handleRouteChange}
                    />
                    {/* <QuickView {...props}
                            enquiryid={showpanel.enquiryid}
                            isFilterApplied={true}
                            updateListEnquiry={updateListEnquiry}
                            sidepanelClose={sidepanelClose}
                        /> */}
                </SidePanelDetailView>
            )}
        </RouteConsumer>
        {/* )}
            </StageConsumer>
        </StageProvider> */}
    </div >
    )
}

export default DealsLog;