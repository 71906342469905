import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import _ from 'lodash';
import { ContentOverlay } from '../../components';
import images from '../../images';
import moment from 'moment';
import CommonHelper from '../../services/common';

const ChangeLogs = (props) => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true)
    const [searchText, setsearchText] = useState('')
    const _allUser = props?.dealersettings?.allUsers || [];

    useEffect(() => {
        window.firebase.firestore().collection('changeLogs')
            .where('recordId', "in", props.recordIDs)
            .get()
            .then(snaps => {
                if (snaps.size > 0) {
                    const _data = [];
                    snaps.docs.forEach(rec => {
                        _data.push({
                            ...rec.data(),
                            id: rec.id
                        });
                    });
                    setData(filterData(_data, props.params.column.colDef)
                        .map(r => {
                            return {
                                ...r,
                                oldValue: getValue(r?.oldData, props.params.column.colDef, r),
                                newValue: getValue(r?.newData, props.params.column.colDef, r),
                            }
                        })
                    )
                    setLoader(false)
                }
                else {
                    setLoader(false)
                }
            })
    }, [])

    const filterData = (data, colDef) => {
        const values = [];
        if (colDef?.modalFields?.length) {
            colDef.modalFields.forEach(rec => {
                if (rec.fieldDB && data.some(a => a?.newData?.[rec.fieldDB])) {
                    values.push(...data.filter(a => a?.newData?.[rec.fieldDB]).map(r => {
                        return {
                            ...r,
                            ...rec,
                            fieldName: rec.name,
                        }
                    }))
                }
            })
        } else if (colDef.nestedObj) {
            if (data.some(a => a?.newData?.[colDef.nestedObj]?.[colDef.fieldDB])) {
                values.push(...data.filter(a => a?.newData?.[colDef.nestedObj]?.[colDef.fieldDB]).map(r => {
                    return {
                        ...r, fieldName: colDef.headerName,
                    }
                }))
            }
        }
        else if (colDef.fieldDB) {
            if (data.some(a => a?.newData?.[colDef.fieldDB])) {
                values.push(...data.filter(a => a?.newData?.[colDef.fieldDB]).map(r => {
                    return {
                        ...r, fieldName: colDef.headerName,
                    }
                }))
            }
        }
        return _.orderBy(values, 'logDate.seconds', 'desc');
    }

    const getValue = (data, colDef, fullData) => {
        let value = 'empty';
        let _data = data[colDef.fieldDB];
        if (colDef.nestedObj) {
            _data = data?.[colDef.nestedObj]?.[colDef.fieldDB]
        }
        if (!colDef?.modalFields?.length && colDef.fieldDB && _.isEmpty(_data?.toString()))
            return value;


        if (colDef.dataType === 'date') {
            value = _data?.seconds ? moment.unix(_data.seconds).format(`${props?.deallogSettings?.dateFormat || 'DD/MM/YYYY'}`) : _data
        } else if (colDef.dataType === 'select') {
            const options = props?.dealersettings?.[colDef?.settingsField] || []
            value = _.find(options, { value: _data })?.name || _data;
        } else if (colDef.dataType === 'multiselect') {
            const options = props?.dealersettings?.[colDef?.settingsField] || []
            value = _.filter(options, (v) => _.indexOf(_data, v.value) >= 0)?.map(r => { return r.name })?.join(',') || _data?.join(',') || '';
        } else if (colDef.dataType === 'price') {
            value = `${props.currencySymbol} ${Math.floor(_data).toLocaleString()}`;
        } else if (colDef?.modalFields?.length) {
            if (fullData.type === 'select') {
                const options = props?.dealersettings?.[fullData?.settingsField] || []
                value = _.find(options, { value: data[fullData.fieldDB] })?.name || data[fullData.fieldDB] || 'empty';
            } else {
                value = data[fullData.fieldDB] || 'empty';
            }
        }
        else {
            value = _data || '';
        }

        return value;
    }

    const sortedList = useMemo(() => data
        .filter(
            ({ fieldName, oldValue, newValue }) =>
            (!_.isEmpty(searchText) ?
                (_.isString(fieldName) ? fieldName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (_.isString(oldValue) ? oldValue.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (_.isString(newValue) ? newValue.toLowerCase().includes(searchText.toLowerCase()) : false)
                : true)
        )
        , [data, searchText])

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName={`modal-dialog-scrollable modal-dialog-centered modal-deallog-history`}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="deallog-history-filterwrap">
                    <div className="deallog-history-search">
                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={e => setsearchText(e.target.value)} />
                    </div>
                </div>

                {
                    loader ? (<div style={{ height: '200px' }}><ContentOverlay active={true} />   </div>) : (
                        <div className='deallog-history-list-table'>
                            <div className='common-table'>
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="head-light" width="20%">Date</th>
                                            <th scope="col" className="head-light" width="20%">Edited By</th>
                                            <th scope="col" className="head-light" width="20%">Field Name</th>
                                            <th scope="col" className="head-light" width="20%">Old Values</th>
                                            <th scope="col" className="head-light" width="20%">New Values</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sortedList.length > 0 ?
                                                sortedList.map((rec, index) => {
                                                    const logBy = rec.logBy ? CommonHelper.getUserNamebyId(_allUser, rec.logBy, 'System') : 'System';
                                                    return <tr key={index}>
                                                        <td>{moment.unix(rec.logDate.seconds).format(`${props?.deallogSettings?.dateFormat || 'DD/MM/YYYY'} ${props?.deallogSettings?.timeFormat || 'hh:mm A'}`)}</td>
                                                        <td>{logBy}</td>
                                                        <td>{rec.fieldName}</td>
                                                        <td>{rec.oldValue}
                                                            {/* <span class="undo-link"><a href="#">UNDO</a></span> */}
                                                        </td>
                                                        <td>{rec.newValue}</td>
                                                    </tr>
                                                })
                                                : (
                                                    (<tr>
                                                        <td colSpan={5}>
                                                            <div className="common-table">
                                                                <div className='h-100  w-100'>
                                                                    <div className='spinner-loader h-100'>
                                                                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                                                                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                                                            <div className="no-data-txt mt-2">
                                                                                <p>{`No data found`}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }

            </Modal.Body>
        </Modal >
    );
};

export default ChangeLogs;