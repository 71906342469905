import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import _ from 'lodash'
import Departments from './departments';
import Translate from '../../../constants/translate';
import DealLogSettings from './settings';
import { TemplateContext } from './templateContext'
import Permissions from './permissions';

const DealLogWorkFlowSettings = (props) => {
    const [active, setActive] = useState('settings')
    const [settings, setSettings] = useState({});
    const [loader, setLoader] = useState({});

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = "settings"
        }
        return key
    }

    useEffect(() => {
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setSettings({
                        ...doc.data(),
                        revenueCalc: doc.data()?.revenueCalc || [{ action: 'add' }]
                    });
                } else {
                    setSettings({
                        revenueCalc: [{ action: 'add' }]
                    });
                }
                setLoader(false)
            })
    }, [])

    const allsettings = useMemo(() => settings, [settings])


    return (
        <>
            <Tab.Container defaultActiveKey={`${getQueryparam()}`} mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4> <Translate text={'Deal Log Workflow Setup'} /></h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="settings" bsPrefix="li-a-nav"> <Translate text={'Settings'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="departments" bsPrefix="li-a-nav"> <Translate text={'Departments'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="permission" bsPrefix="li-a-nav"> <Translate text={'Permissions'} /></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <TemplateContext.Provider value={{ loading: loader, allsettings: allsettings }}>
                    <div className={`settings-container mb-0 ${liteVersion ? '' : 'h-100'}`} style={liteVersion ? { overflow: 'hidden' } : {}}>
                        <div className="settings-container-sub" >
                            <Tab.Pane eventKey="settings">
                                <DealLogSettings activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="departments">
                                <Departments activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="permission">
                                <Permissions activeKey={active} {...props} />
                            </Tab.Pane>
                        </div>
                    </div>
                </TemplateContext.Provider>
            </Tab.Container>
        </>
    )
}

export default DealLogWorkFlowSettings;