import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { PieChart } from '../../components';


const RevenueTarget = (props) => {
    useEffect(() => {
        if (_.isEmpty(props?.target?.toString())) {
            return;
        }
        const leadCount = props[`${props.data.value}Leads`].length || 0;
        const percent = props.target > 0 ? Math.round((100 / props.target) * leadCount) : 0;
        const el = `#${props.data.value}-targetChart`;
        if (percent > 100) {
            percent = 100;
        } else if (percent < 0) {
            percent = 0;
        }
        var deg = Math.round(360 * (percent / 100));

        if (percent > 50) {
            window.$(el + ' .pie').css('clip', 'rect(auto, auto, auto, auto)');
            window.$(el + ' .right-side').css('transform', 'rotate(180deg)');
        } else {
            window.$(el + ' .pie').css('clip', 'rect(0, 1em, 1em, 0.5em)');
            window.$(el + ' .right-side').css('transform', 'rotate(0deg)');
        }
        window.$(el + ' .right-side').css('border-width', '0.1em');
        window.$(el + ' .left-side').css('border-width', '0.1em');
        window.$(el + ' .shadow').css('border-width', '0.1em');
        window.$(el + ' .num').text(props.target);
        window.$(el + ' .left-side').css('transform', 'rotate(' + deg + 'deg)');
        window.$(el + ' .right-side').attr('title', `${percent}%`);
        window.$(el + ' .left-side').attr('title', `${percent}%`);
    }, [props[`${props.data.value}Leads`]?.length])

    return <>
        <div className="col">
            <div className="dynamic-module-counter-new">
                {
                    props.data.value === 'reported' ? (<>
                        <div className="dynamic-module-counter-head">

                            <div className="dynamic-module-header-text">{props.data.name}</div>
                            <div className="dynamic-module-header-value">Reportable <span className="dynamic-module-link-white"><a href="#" onClick={(e) => {
                                e.preventDefault();
                                props.targetClick('reportable')
                            }}>{props.reportableLeads.length}</a></span></div>
                        </div>

                        <div className="dynamic-module-counter-flex">
                            <div className="dynamic-module-counter-inner">
                                <div className="dynamic-module-blue-new cursor-pointer" onClick={(e) => {
                                    e.preventDefault();
                                    props.targetClick('reported')
                                }}>{props.reportedLeads.length} Units</div>
                                <div className="dynamic-module-value-new">Remaining  <span className="dynamic-module-link"><a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    props.targetClick('remaining')
                                }}>{props.remainingLeads.length}</a></span></div>
                            </div>
                            {
                                !_.isEmpty(props?.target?.toString()) ? (
                                    <div>
                                        <div id={`${props.data.value}-targetChart`} className="donut-size">
                                            <div className="pie-wrapper">
                                                <span class="label">
                                                    <div class="pie-title-container">
                                                        <i>Target</i>
                                                        <span class="num">{props.target}</span>
                                                    </div>
                                                </span>
                                                <div className="pie">
                                                    <div className="left-side half-circle"></div>
                                                    <div className="right-side half-circle"></div>
                                                </div>
                                                <div className="shadow"></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<div><div className='donut-size pie-wrapper'></div></div>)
                            }
                        </div>
                    </>) : (<>
                        <div className="dynamic-module-counter-head">
                            <div className="dynamic-module-header-text">{props.data.name}</div>
                            <div className="dynamic-module-header-value"><NumberFormat value={_.sumBy(props[`${props.data.value}Leads`], 'revenueAmount')} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={props.currencySymbol} />
                            </div>
                        </div>
                        <div className="dynamic-module-counter-flex">
                            <div className="dynamic-module-counter-inner">
                                <div className="dynamic-module-blue-new cursor-pointer" onClick={(e) => {
                                    e.preventDefault();
                                    props.targetClick(props.data.value)
                                }}>{props[`${props.data.value}Leads`].length} Units</div>
                                <div className="dynamic-module-value-new">
                                    {
                                        props[`${props.data.value}Leads`].length > 0 ? (
                                            <NumberFormat value={_.meanBy(props[`${props.data.value}Leads`], 'revenueAmount')} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={props.currencySymbol} />

                                        ) : (<>{props.currencySymbol} 0</>)
                                    }
                                    <span className='dynamic-module-value-avg'>Avg</span>
                                </div>
                            </div>
                            {
                                !_.isEmpty(props?.target?.toString()) ? (
                                    <div>
                                        <div id={`${props.data.value}-targetChart`} className="donut-size">
                                            <div className="pie-wrapper">
                                                <span class="label">
                                                    <div class="pie-title-container">
                                                        <i>Target</i>
                                                        <span class="num">{props.target}</span>
                                                    </div>
                                                </span>
                                                <div className="pie">
                                                    <div className="left-side half-circle"></div>
                                                    <div className="right-side half-circle"></div>
                                                </div>
                                                <div className="shadow"></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<div><div className='donut-size pie-wrapper'></div></div>)
                            }
                        </div>
                    </>)
                }

            </div>
        </div>
    </>
}

export default RevenueTarget;