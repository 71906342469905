export const permissionsValues = [
    { name: 'Read Only', searchlabel: 'readonly', value: 'readonly' },
    { name: 'Hide', searchlabel: 'hide', value: 'hide' },
    { name: 'Edit', searchlabel: 'edit', value: 'edit' }
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

export const cellProps = {
    default: true,
    width: 120,
    cellClassRules: {
        'deallog-edit-block': params => params.value === 'edit',
        'deallog-readonly-block': params => params.value === 'readonly',
        'deallog-hide-block': params => params.value === 'hide',
    },
    settingsField: 'permissionsValues',
    html: true,
    wrapHeaderText: true, // Wrap Text
    autoHeaderHeight: true, // Adjust Cell Height to Fit Wrapped Text
}