import React, { forwardRef, useState, useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import _ from 'lodash';

const AddressPicker = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const addressInputRef = useRef();


    useEffect(() => {
        if (addressInputRef.current) {
            //console.log(addressInputRef.current)
            addressInputRef.current.refs.input.focus()
            //dateInputRef.current.show();
        }
    }, [])

    const bindAddress = (place) => {

        let state = Object.assign({});
        let street = '';
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            postal_code: 'short_name'
        };

        if (place.formatted_address !== null)
            state['address'] = place.formatted_address;

        if (!_.isEmpty(place.address_components)) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];

                    if (addressType === "postal_code")
                        state['areaCode'] = val;

                    if (addressType === "administrative_area_level_1")
                        state['state'] = val;

                    if (addressType === "locality")
                        state['suburb'] = val;

                    if (addressType === "country") {
                        state['country'] = val;
                    }
                    if (addressType === "street_number" || addressType === "route") {
                        street += ` ${val}`;
                    }
                }
            }
        }

        if (street) {
            state['street'] = street.trim();
        }
        props.api.stopEditing();
        props.api.getRowNode(props.rowIndex).setDataValue(props.colDef.field, (state.address || null))
    };

    const handleChange = (date) => {
        console.log(date)
        // setValue(date);
        // props.api.stopEditing();
        // if (props?.colDef?.formatSave) {
        //     if (props.colDef.fieldDB !== props.colDef.field)
        //         props.data[props.colDef.fieldDB] = (date ? moment(date).format(props?.colDef?.formatSave) : null);
        //     props.api.getRowNode(props.rowIndex).setDataValue(props.colDef.field, (date ? moment(date).format(props?.colDef?.format || 'DD/MM/YYYY') : null))
        // } else {
        //     if (props.colDef.fieldDB !== props.colDef.field)
        //         props.data[props.colDef.fieldDB] = (date ? window.firebase.firestore.Timestamp.fromDate(moment(moment(date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm')).toDate()) : null);
        //     props.api.getRowNode(props.rowIndex).setDataValue(props.colDef.field, (date ? moment(date).format('DD/MM/YYYY') : null))
        // }
    };

    return (
        <div className="ag-input-wrapper" style={{
            width: props.eGridCell.clientWidth,
            height: props.eGridCell.clientHeight,
            boxSizing: 'border-box'
        }}>
            <Autocomplete
                ref={addressInputRef}
                className={`form-control`}
                onPlaceSelected={(place) => {
                    bindAddress(place);
                }}
                types={['address']}
                value={value}
                placeholder={'search address'}
                onChange={handleChange}
                componentRestrictions={props.data['country'] ? { country: props.data['country'].toLowerCase() } : { country: [] }}
                name="address"
            />
        </div>
    );
});

export default AddressPicker;