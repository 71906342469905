import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
import { NavLink } from 'react-router-dom';
import Translate from '../../constants/translate';
import { msalConfig } from "../../services/msal/authConfig";
import Filters from './filters'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [activityFilter, setactivityFilter] = useState({})
    //const [searchText, setSearchText] = useState(props.searchText)

    // useEffect(() => {
    //     setSearchText(props.searchText);
    // }, [props.searchText])

    useEffect(() => {
        if (!_.isEmpty(localStorage.activityFilter) && Object.keys(JSON.parse(localStorage.activityFilter)).length > 0) {
            let _activityFilter = JSON.parse(localStorage.activityFilter);
            let _filterValue = _activityFilter.value;
            if (JSON.parse(localStorage.eventFilter).join(',') !== 'all') {
                _activityFilter = {
                    ..._activityFilter,
                    value: {
                        ..._activityFilter.value,
                        subType: JSON.parse(localStorage.eventFilter).join(',')
                    }
                }
            }

            if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                setSearchKeywords(_filterValue['keywords']);
            else
                setSearchKeywords('');

            setactivityFilter(_activityFilter);
        }
        else {
            setactivityFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.activityFilter, localStorage.eventFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(activityFilter)) {
                let _activityFilter = Object.assign({}, activityFilter)
                if (!_.isEmpty(activityFilter.value)) {
                    // update with the existing keyword
                    _activityFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _activityFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_activityFilter);
            }
            else {
                // create new filter with keyword
                let _activityFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_activityFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(activityFilter)) {
                let _activityFilter = Object.assign({}, activityFilter)
                if (!_.isEmpty(activityFilter.value)) {
                    if (Object.keys(activityFilter.value).length > 1) {
                        delete _activityFilter.value['keywords']
                        props.handleApplyFilter(_activityFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };
    //#endregion

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    const handleSignIn = () => {
		const authURL = `${msalConfig.authority}oauth2/v2.0/authorize?
			client_id=${msalConfig.clientId}
			&response_type=code
			&redirect_uri=${encodeURIComponent(`${window.location.origin}/settings/profile`)}
			&response_mode=query
			&scope=${msalConfig.scopes}
			&state=outlookcalendar`

        window.location.replace(authURL);
    }

    return (
        <div className="filter-panel">
            <div className="filter-switch">
                <NavLink to="/calendar" exact activeClassName={"switch-active"}>
                    <i className="ico icon-calendar-tab"></i>
                </NavLink>
                <NavLink to="/calendar/list" className="ml-1" exact activeClassName={"switch-active"}>
                    <i className="ico icon-list"></i>
                </NavLink>
            </div>

            {/* FILTER SEARCH KEYWORDS*/}
            {/* <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="quick search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value
                            setSearchText(_val);
                            localStorage.eventSearchText = _val;

                            if (_val && _val.length > 2) {
                                props.handlesearchText(_val)
                            }

                            if (!_val)
                                props.handlesearchText('')
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                localStorage.eventSearchText = searchText;
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.eventSearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                localStorage.eventSearchText = searchText;
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div> */}
            <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchKeywords ? searchKeywords : ''}
                        onChange={(e) => {
                            handleKeywordsOnChange(e);
                            if (!e.target.value)
                                handleSearchKeywords('')
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchKeywords(searchKeywords)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchKeywords && searchKeywords.length > 0
                                ?
                                <a
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchKeywords('');
                                        handleSearchKeywords('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleSearchKeywords(searchKeywords)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>

            {props.isOutlookCalendarExpired && (
                <div className='outlook-item-btn ml-2' onClick={() => {handleSignIn()}}>
                    <div className='outlook-logo'>
                        <i className='ico icon-mail-spam'></i>
                    </div>
                    <div className='outlook-inner-text'>
                        <div className='outlook-inner-text-top'> <Translate text={'Outlook calendar session expired!'}/> </div>
                        <div className='outlook-inner-text-sub'>
                            {' '}
                            <Translate text={'Click here to reauthenticate'}/>{' '}
                        </div>
                    </div>
                </div>
            )}




            <div className="float-right">
                <div className="filter-add-button">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        props.handleAddActivity()
                    }}> <i className="ico icon-add"></i> <Translate text={'activity'} /></button>
                </div>
                {
                    (props.showMore && exportData)
                        ?
                        <>
                            <div className="filter-item-btn ml-2">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    props.excelDownload()
                                }} title={'Excel Export'}>
                                    <i className="ico icon-excel-download"></i>
                                </a>
                                <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`activity_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                    <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                </ExcelFile>
                                {/* <CSVLink
                                            ref={props.csvBtn}
                                            uFEFF={false}
                                            data={props.csvData}
                                            headers={props.csvHeader}
                                            filename={`activity_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}
                                            style={{ display: 'none' }}>
                                        </CSVLink> */}
                            </div>
                        </>
                        :
                        <>
                        </>
                }
                {/* <div className="filter-view-more  ml-2">
                    <a className="common-button"><i className="ico icon-more"></i></a>
                </div> */}
            </div>

            {/**** FILTERS ****/}
            <Filters
                searchKeywords={searchKeywords}
                activityFilter={activityFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                showDateFilter={props.showMore}
            >
            </Filters>

        </div>
    )
}

export default FilterPanel