import React, { forwardRef, useState, useEffect } from 'react';
import { ContentOverlay, InputText, PopoverPanel } from '..';
import _ from 'lodash';
import { firestoreDB } from '../../services/helper';
import CommonHelper from '../../services/common';
import moment from 'moment';
import MentionEditor from '../mentionEditor/mentionEditor';
import Translate from '../../constants/translate';

const CustomPopover = forwardRef((props, ref) => {
  const [popover, setPopover] = useState({})
  const [notes, setNotes] = useState([])
  const [loader, setLoader] = useState(false)
  const [mentions, setMentions] = useState([]);
  const [errorNotes, setErrorNotes] = useState('')
  const [btnloader, setbtnloader] = useState(false)
  const [activityLog, setActivitylog] = useState(false)
  const [reloadData, setreloadData] = useState(false)
  const _allUser = props?.dealerSettings?.allUsers || [];
  const mentionsData = _allUser.filter(a => a.id !== localStorage.uid && a.active === true).map(clientUser => {
    return {
      name: `@${clientUser.name}`,
      id: clientUser.id,
      avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
    };
  });

  useEffect(() => {
    let newlogVM = Object.assign({});
    newlogVM.type = 'log';
    newlogVM.subType = 'note';
    newlogVM.owner = localStorage.uid;
    newlogVM.addedBy = localStorage.uid;
    newlogVM.modifiedBy = localStorage.uid;
    newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
    newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
    newlogVM.enquiryID = props.data.documentID;
    newlogVM.contactID = props?.data?.contactID || null;
    newlogVM.clientID = props?.data?.clientID || null;
    newlogVM.documentID = firestoreDB({ client: { projectId: props.data.projectId } }).firestore().collection('activities').doc().id;
    newlogVM.startDate = window.firebase.firestore.Timestamp.now();
    newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment().add(1, 'hours')._d);
    newlogVM.addedByName = CommonHelper.getUserNamebyId(_allUser, localStorage.uid);
    newlogVM.modifiedByName = CommonHelper.getUserNamebyId(_allUser, localStorage.uid);
    newlogVM.projectId = props.data.projectId || null;
    newlogVM.notes = null;
    newlogVM.isDeleted = false;
    newlogVM.isDone = true;
    setActivitylog(newlogVM);
  }, [reloadData]);

  useEffect(() => {
    setPopover({ show: true, target: props.eGridCell })
  }, [])

  useEffect(() => {
    setLoader(true)
    firestoreDB({ client: { projectId: props.data.projectId } }).firestore()
      .collection(`activities`)
      .where('isDeleted', '==', false)
      .where('isDone', '==', true)
      .where('enquiryID', '==', props.data.documentID)
      .get()
      .then((snap) => {
        const _notes = [];
        if (snap.size > 0) {
          snap.docs.forEach(doc => {
            if (doc.data().notes) {
              _notes.push(doc.data())
            }
          });
        }
        setNotes(_.orderBy(_notes, 'addedDate.seconds', 'desc'));
        setLoader(false)
      })

  }, [])


  const onMentionChange = notesText => {
    setActivitylog({
      ...activityLog,
      notes: notesText
    });
  };

  const onMentionSelect = (option, ownerID) => {
    // console.log('select', option);
    const { name, id } = option;
    let idToPass = id
    // return if user is mentioning himself
    if (idToPass === localStorage.uid) {
      return;
    }

    // Check to avoid adding duplicate entries in mention
    const check = mentions.filter(mention => mention.id === idToPass);

    if (check.length === 0) {
      setMentions([...mentions, { name, id: idToPass }]);
    }
  };

  const handleSave = (e) => {
    if (_.isEmpty(activityLog?.notes?.trim())) {
      setErrorNotes('input_error');
      return;
    }
    if (mentions.length > 0) {
      let convertedIDs = []
      let rawIDs = mentions.map(mentionObject => mentionObject.id)
      rawIDs.forEach(id => {
        if (id.includes(',')) {
          let allIDs = id.split(',')
          allIDs.forEach(a => {
            convertedIDs.push(a)
          })
        } else {
          convertedIDs.push(id)
        }
      })
      activityLog.mentions = _.uniq(convertedIDs)
    }
    setbtnloader(true);
    firestoreDB({ client: { projectId: props.data.projectId } }).firestore().doc(`activities/${activityLog.documentID}`)
      .set(activityLog)
      .then(doc => {
        props.api.stopEditing();
        props.api.getRowNode(props.rowIndex).setDataValue(props.colDef.field, activityLog.notes)
      })

  }
  return (
    <PopoverPanel
      showpopover={popover.show}
      targetpopover={popover.target}
      title={<><i className="ico icon-message mailnotifiy-pop-title"></i>  Comments</>}
      position='left'
      id='pipeline-switch'
      className='popover-pipeline-switch'
      closepopover={() => {
        setPopover({ show: false });
        props.api.stopEditing();
      }}>
      <div style={{ padding: '20px' }}>
        <div className='ag-popover-comments'>
          {
            loader ? (<ContentOverlay active={true} />) : (<>
              {
                notes.map((rec, index) => {
                  const logBy = rec.modifiedByName ? rec.modifiedByName : (rec.modifiedBy ? CommonHelper.getUserNamebyId(_allUser, rec.modifiedBy) : '');
                  return <div key={index} id={rec.documentID} className="deallog-comments-custom-wrap mb-2">
                    <div>
                      <div className="deallog-comments-owner">
                        <div className="deallog-comments-icon deallog-comments-icon-blue"> <i className={`ico icon-${rec.type === 'activity' ? 'activity-' : ''}${rec.subType}`}></i> </div>
                        <div> <div> {logBy} </div>
                          <div className="deallog-comments-time">{rec.addedDate ? moment.unix(rec.addedDate.seconds).format(`DD/MM/YYYY hh:mm A`) : ''}</div></div>
                      </div>
                      <div className="deallog-comments-text">{rec.notes}</div>
                    </div>
                  </div>
                })
              }
            </>
            )
          }
        </div>
        <div className='deallog-comments-add-wrap form-group mb-0'>
          <label >Comments</label>
          <MentionEditor
            mentionsData={mentionsData}
            onMentionChange={onMentionChange}
            onMentionSelect={onMentionSelect}
            notes={activityLog.notes || ''}
            focusOnMount={false}
            isNoteEmpty={errorNotes === 'input_error' ? true : false}
            clientUsers={_allUser}
            ownerID={localStorage.uid}
            className={'notes'}
          />
        </div>
        <div class="mt-2">
          <button onClick={handleSave} className="btn btn-primary float-right">
            {
              btnloader ? (
                <span
                  className='spinner-border spinner-button mr-1'
                  role='status'
                  aria-hidden='true'
                ></span>
              ) : (
                <></>
              )}
            <Translate text={'save'} /></button>
        </div>
      </div>
    </PopoverPanel>
  );
});

export default CustomPopover;