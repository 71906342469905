import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { ContentOverlay, ReactMultiSelect, ReactSelect, UpgradePlan } from '../../../../components';
import Translate from '../../../../constants/translate';
import toast from 'toasted-notes'
import { columnsAction, dateFormats, timeFormats } from './viewModel';
import moment from 'moment';
import { TemplateContext } from '../templateContext'
import { enqiuryOptions } from '../../workflow/enquiryOptions/viewModel';
import Swal from 'sweetalert2';

const DealLogSettings = (props) => {
    const [settings, setSettings] = useState({})
    const { allsettings, loader } = useContext(TemplateContext);
    const [enquiryPriceFields, setenquiryPriceFields] = useState([])
    const [enquiryDateFields, setenquiryDateFields] = useState([])
    const enquiryOptionsStatic = props?.dealersettings?.client?.settings?.enquiryOptionsStatic || [];
    const enquiryOptionsDF = props?.dealersettings?.client?.settings?.enquiryOptionsDF || [];
    const saleTypes = props?.dealersettings?.client?.settings?.salesType || [];

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);


    useEffect(() => {
        const _fields = enqiuryOptions
            .filter(a => a.type === 'price')
            .map(r => {
                return {
                    type: r.type,
                    label: _.find(enquiryOptionsStatic, { value: r.value })?.name || r.name,
                    value: r.value
                }
            })
        enquiryOptionsDF?.filter(a => a.active === true && (a.type === 'price' || a.type === 'date') && a.level === 'oem')?.forEach(rec => {
            _fields.push({
                type: rec.type,
                label: rec.name,
                value: rec.value
            })
        })
        setenquiryPriceFields(_fields.filter(a => a.type === 'price'));
        setenquiryDateFields(_fields.filter(a => a.type === 'date'));
    }, [])

    useEffect(() => {
        setSettings(allsettings || {})
    }, [allsettings])

    const handleSelect = (e, data, type) => {
        const _settings = type ? {
            ...settings,
            [type]: settings[type] ? {
                ...settings[type],
                [data.name]: e?.value || null
            } : {
                [data.name]: e?.value || null
            }
        } : {
            ...settings,
            [data.name]: e?.value || null
        }
        setSettings(_settings)
        handleSave(_settings);
    }

    const handleMultiSelect = (selectedOptions, name, type) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        const _settings = type ? {
            ...settings,
            [type]: settings[type] ? {
                ...settings[type],
                [name]: value || null
            } : {
                [name]: value || null
            }
        } : {
            ...settings,
            [name]: value || null
        }
        setSettings(_settings)
        handleSave(_settings);
    }


    const handleSave = (_settings) => {
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`)
            .set(_settings, { merge: true })
            .then(() => {
                toast.notify('Settings updated successfully', {
                    duration: 2000
                });
            })
    }

    const handlerevenueCalcSelect = (e, data, i) => {
        const _settings = {
            ...settings,
            revenueCalc: _.map(settings.revenueCalc, (rec, j) => {
                if (i === j) {
                    return _.assign({}, rec, { [data.name]: e?.value || null });
                }
                return rec;
            })
        }
        setSettings(_settings);
        handleSave(_settings);
    }

    const handlereAdd = (e) => {
        e.preventDefault();
        const _settings = {
            ...settings,
            revenueCalc: [
                ...settings.revenueCalc,
                { action: 'add' }
            ]
        };
        setSettings(_settings);
        handleSave(_settings);
    }

    const handlereRemove = (rec) => {
        const _settings = {
            ...settings,
            revenueCalc: settings.revenueCalc.filter(r => r.value !== rec.value)
        };
        setSettings(_settings);
        handleSave(_settings);
    }

    return (
        <>
            {
                liteVersion && !loader ? (<UpgradePlan />) : (<></>)
            }
            <h2> <Translate text={'Settings'} /> </h2>
            <div className="divider-line"></div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                    <div className="form-style w-100">
                        <div className="form-row mt-2">
                            <div className="col-md-3">
                                <p className="mb-2 w-100">Date Format</p>
                                <ReactSelect
                                    options={dateFormats.map(rec => {
                                        return {
                                            label: moment().format(rec.value),
                                            value: rec.value,
                                        }
                                    })}
                                    name={"dateFormat"}
                                    placeholder={'select date format'}
                                    onChange={handleSelect}
                                    value={settings?.dateFormat || ''}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-2 w-100">Time Format</p>
                                <ReactSelect
                                    options={timeFormats.map(rec => {
                                        return {
                                            label: moment().format(rec.value),
                                            value: rec.value,
                                        }
                                    })}
                                    name={"timeFormat"}
                                    placeholder={'select time format'}
                                    onChange={handleSelect}
                                    value={settings?.timeFormat || ''}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                        {
                            settings?.revenueCalc?.map((rec, index) => {
                                return <div key={index} className='form-row mt-2'>
                                    {
                                        index === 0 ? (
                                            <></>
                                        ) : (
                                            <div className={`col-md-1`}>
                                                <ReactSelect
                                                    options={columnsAction}
                                                    name={"action"}
                                                    placeholder={'select field'}
                                                    onChange={(e, data) => handlerevenueCalcSelect(e, data, index)}
                                                    value={rec.action || ''}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        )
                                    }
                                    <div className={`col-md-${index === 0 ? '3' : '2'}`}>
                                        <p className="mb-2 w-100">
                                            {
                                                index === 0 ? 'Revenue Calculation' : ' '
                                            }
                                        </p>
                                        <ReactSelect
                                            options={enquiryPriceFields.filter(a => !settings?.revenueCalc.filter(r => r.value !== rec.value).map(r => { return r.value }).includes(a.value))}
                                            name={"value"}
                                            placeholder={'select field'}
                                            onChange={(e, data) => handlerevenueCalcSelect(e, data, index)}
                                            value={rec.value || ''}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="mb-2 w-100">&nbsp;</p>
                                        {
                                            index === 0 ? (
                                                <button className="btn btn-primary float-left ml-2" onClick={(e) => handlereAdd(e)}><i className="ico icon-add mr-2"></i> Add</button>
                                            ) : (
                                                <a href="#"
                                                    className="mini-button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        Swal.fire({
                                                            title: 'Are you sure?',
                                                            text: `You want to delete?.`,
                                                            icon: 'info',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Yes',
                                                            cancelButtonText: 'No',
                                                        }).then(result => {
                                                            if (result.value) {
                                                                handlereRemove(rec)
                                                            }
                                                        });
                                                    }}>
                                                    <i className="ico icon-delete"></i>
                                                </a>
                                            )
                                        }
                                    </div>
                                </div>
                            })
                        }
                        <div className="form-row mt-2">
                            <div className="col-md-3">
                                <p className="mb-2 w-100">Reported Calculation</p>
                                <ReactSelect
                                    options={enquiryDateFields}
                                    name={"field"}
                                    placeholder={'select date field'}
                                    onChange={(e, data) => handleSelect(e, data, 'reportedCalc')}
                                    value={settings?.reportedCalc?.field || ''}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-2 w-100">Exclude Sale Type</p>
                                <ReactMultiSelect
                                    options={saleTypes.filter(e => e.active === true)?.map(r => { return { label: r.name, value: r.value } })}
                                    name={"excludeSaleType"}
                                    placeholder={'select sale type'}
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'excludeSaleType', 'reportedCalc')}
                                    value={settings?.reportedCalc?.excludeSaleType || []}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}

export default DealLogSettings;