/** LIBRARIES */
import React from 'react';
import _ from 'lodash'
import { default as _images } from '../images';


const SidePanelDetailView = (props) => {
    const { clsActive, clsName, title } = props;

    const handleClose = (e) => {
        e.preventDefault();

        let pathname = window.location.pathname;
        if (pathname.indexOf('enquiry/details') >= 0 ||
            pathname.indexOf('contacts/details') >= 0 ||
            pathname.indexOf('tradeinpro/details') >= 0 ||
            pathname.indexOf('eventspro/details') >= 0 ||
            pathname.indexOf('wholesaler/details') >= 0 ||
            pathname.indexOf('service/details') >= 0 ||
            pathname.indexOf('fleetpro/details') >= 0 ||
            pathname.indexOf('/dashboard') >= 0 ||
            pathname.indexOf('/dashboard/bdc') >= 0 ||
            pathname.indexOf('/dashboard/service') >= 0 ||
            pathname.indexOf('/dashboard/events') >= 0 ||
            pathname.indexOf('/dashboard/tradeinpro') >= 0) {
            document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        } else if (
            (pathname.indexOf('pipeline') >= 0 ||
                pathname.indexOf('inbound') >= 0 ||
                pathname.indexOf('wholesaler') >= 0 ||
                pathname.indexOf('tradeinpro') >= 0 ||
                pathname.indexOf('aftersales') >= 0 ||
                pathname.indexOf('service') >= 0 ||
                pathname.indexOf('/kitchen') >= 0) &&
            (pathname.indexOf('ppsr/list') < 0 &&
                pathname.indexOf('tradeinpro/list') < 0 &&
                pathname.indexOf('eventspro/list') < 0 &&
                pathname.indexOf('fleetpro/list') < 0 &&
                pathname.indexOf('fleetpro/history') < 0 &&
                pathname.indexOf('eventspro/attendees') < 0 &&
                pathname.indexOf('service/list') < 0 &&
                pathname.indexOf('pipeline/oemlist') < 0 &&
                pathname.indexOf('pipeline/list') < 0 &&
                pathname.indexOf('inbound/list') < 0 &&
                pathname.indexOf('tipinbound/list') < 0 &&
                pathname.indexOf('inbound/allocation') < 0)) {
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        props.sidepanelClose();
    }
    
    return (
        <>
            <div className={"overlay " + clsActive} onClick={(e) => { handleClose(e) }}> </div>
            <nav className={`${clsName + ' ' + clsActive}`}>
                {/* <div className="dismiss" onClick={(e) => { handleClose(e) }}>
                    <img src={_images.arrowclose} alt="Close" width="20" height="18" />
                </div> */}
                {clsActive ? props.children : <></>}
            </nav>
        </>
    );
}

export default SidePanelDetailView;