/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash'
import { NavLink, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix';
import moment from 'moment'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import Filters from './filters'
import { enquiryStatus } from '../../services/enum';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [pipelineFilter, setPipelineFilter] = useState({})
    const [counts, setCounts] = useState({ open: 0, won: 0, lost: 0, delivered: 0, total: 0 });
    const [searchText, setSearchText] = useState(props.searchText)

    const enquiryStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];


    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        var dpl = props.pipeline.filter(e => e.default)[0] ? props.pipeline.filter(e => e.default)[0].value : '';
        var dataArray = [];
        for (var i in props.stageCounts) {
            if (i !== 'Converted')
                dataArray.push(props.stageCounts[i]);
        }
        if (dpl === 'LeadsBucket') {
            setCounts({
                total: _.sumBy(dataArray, "total")
            })
        }
        else {
            let _counts = {
                open: _.sumBy(dataArray, "open"),
                won: _.sumBy(dataArray, "won"),
                lost: _.sumBy(dataArray, "lost"),
                delivered: _.sumBy(dataArray, "delivered"),
                total: _.sumBy(dataArray, "total")
            };
            enquiryStatus.filter(a => a.value !== 'prospectLost').forEach(sts => {
                _counts = {
                    ..._counts,
                    [sts.value]: _.sumBy(dataArray, sts.value)
                }
            });
            setCounts(_counts);
        }
    }, [props.stageCounts])

    useEffect(() => {
        if (!_.isEmpty(localStorage.pipelineFilter) && Object.keys(JSON.parse(localStorage.pipelineFilter)).length > 0) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filterValue = _pipelineFilter.value;

            if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                setSearchKeywords(_filterValue['keywords']);
            else
                setSearchKeywords('');

            setPipelineFilter(_pipelineFilter);
        }
        else {
            setPipelineFilter({});
            setSearchKeywords('');
        }
    }, [localStorage.pipelineFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(pipelineFilter)) {
                let _pipelineFilter = Object.assign({}, pipelineFilter)
                if (!_.isEmpty(pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_pipelineFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(pipelineFilter)) {
                let _pipelineFilter = Object.assign({}, pipelineFilter)
                if (!_.isEmpty(pipelineFilter.value)) {
                    if (Object.keys(pipelineFilter.value).length > 1) {
                        delete _pipelineFilter.value['keywords']
                        props.handleApplyFilter(_pipelineFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };
    //#endregion
    const _permisions = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ?
        props.dealersettings.rolePermissions.permissions : null;

    const transferEnquiry = ((!_.isEmpty(_permisions) && _permisions.transferEnquiry) ? true : false);
    const movePipeline = ((!_.isEmpty(_permisions) && _permisions.movePipeline) ? true : false);
    const deleteEnquiry = ((!_.isEmpty(_permisions) && _permisions.deleteEnquiry) ? true : false);
    const mergeContact = ((!_.isEmpty(_permisions) && _permisions.mergeContact) ? true : false);
    const exportData = ((!_.isEmpty(_permisions) && _permisions.exportData) ? true : false);

    // const exportToExcel = () => {
    //     const propscsvData = props.csvData;
    //     const worksheetData = [];
    //     if (propscsvData?.length) {
    //         worksheetData.push(propscsvData[0].columns.map(r => { return r.label }));
    //         propscsvData[0].data.forEach(rec => {
    //             worksheetData.push(rec.map(r => { return r.value }));
    //         })
    //     }
    //     var wscols = propscsvData[0].columns.map(r => { return r.width });
    //     const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    //     const workbook = XLSX.utils.book_new();
    //     worksheet['!cols'] = wscols;
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    //     // Generate Excel file
    //     XLSX.writeFile(workbook, `enquiry_${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`);

    // }
    return (
        <div className="filter-panel">
            <div className="filter-switch">
                <NavLink to="/pipeline" exact activeClassName={"switch-active"}>
                    <i className="ico icon-pipeline-1"></i>
                </NavLink>
                <NavLink to="/pipeline/list" className="ml-1" exact activeClassName={"switch-active"}>
                    <i className="ico icon-list"></i>
                </NavLink>
            </div>

            {
                localStorage.defaultModule !== 'oem' && mergeContact && props.showMore
                    ?
                    <div className="filter-item-btn ml-2">
                        <a href="#" onClick={(e) => { e.preventDefault(); props.handleMerge() }}>
                            <i className="ico icon-merge"></i> <Translate text={'merge'} />
                        </a>
                    </div>
                    :
                    <></>
            }

            {/* <div className="filter-item-btn ml-2">
                
            </div> */}

            {/**** KEYWORDS SERACH ****/}
            <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value;

                            setSearchText(_val);
                            localStorage.enquirySearchText = _val;

                            if (!_val)
                                props.handlesearchText('')

                            if (_val && _val.length > 2)
                                props.handlesearchText(_val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.enquirySearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchKeywords ? searchKeywords : ''}
                        onChange={(e) => {
                            handleKeywordsOnChange(e);
                            if (!e.target.value)
                                handleSearchKeywords('')
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchKeywords(searchKeywords)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchKeywords && searchKeywords.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchKeywords('');
                                        handleSearchKeywords('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleSearchKeywords(searchKeywords)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div> */}

            {
                (!_.isEmpty(props.selectedIds) && props.showMore) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span><Translate text={Object.keys(props.selectedIds).length > 1 ? 'Selected Enquiries' : 'Selected Enquiry'} /></span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">
                {
                    props.showMore
                        ?
                        <>
                            {
                                (transferEnquiry || movePipeline || deleteEnquiry)
                                    ?
                                    <>
                                        <Dropdown className='filter-view-more  ml-2'>
                                            <Dropdown.Toggle
                                                as={CustomToggle}
                                                className='common-button'
                                            >
                                                <i className='ico icon-more'></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                as={CustomMenu}
                                                ChildClass="more-dropdown"
                                                xplacement="bottom-end"
                                            >
                                                {
                                                    transferEnquiry ? (
                                                        <Dropdown.Item eventKey="1" onClick={(e) => { props.handleTransfer(e) }}><i className="ico icon-email"></i>  <Translate text={'transfer'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }

                                                {
                                                    movePipeline ? (
                                                        <Dropdown.Item eventKey="2" onClick={(e) => { props.handleMove(e) }}><i className="fa fa-file-import"></i>  <Translate text={'move'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }

                                                {
                                                    deleteEnquiry ? (
                                                        <Dropdown.Item eventKey="3" onClick={(e) => { props.handleDelete(e) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                    </>
                            }

                            {
                                (exportData && counts.total > 0)
                                    ?
                                    <>
                                        <div className="filter-item-btn ml-2">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                props.excelDownload(counts.total)
                                            }} title={'Excel Export'}>
                                                <i className="ico icon-excel-download"></i>
                                            </a>
                                            <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`enquiry_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                                <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                            </ExcelFile>
                                            {/* <CSVLink
                                                ref={props.csvBtn}
                                                uFEFF={false}
                                                data={props.csvData}
                                                headers={props.csvHeader}
                                                filename={`enquiry_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}
                                                style={{ display: 'none' }}>
                                            </CSVLink> */}
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }


                        </>
                        :
                        <>
                        </>
                }



            </div>

            <Dropdown className="filter-pipeline-btn float-right dropdown-toggle" alignRight>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <i className="ico icon-pipeline-1"></i> <span>{props.pipeline.length > 0 ? props.pipeline.filter(e => e.default && e.value !== 'LeadsBucket')[0].name : ''}</span>
                    <i className="ico icon-dropdown"></i>
                </Dropdown.Toggle>
                {
                    !_.isEmpty(props.dealersettings)
                        && !_.isEmpty(props.dealersettings.rolePermissions)
                        && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                        && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                            return props.dealersettings.rolePermissions.admin[id]
                        }).some(e => e === 'workflowSetup') ? (<Dropdown.Item className="edit" bsPrefix="edit" as={Link} to='/settings/workflow?key=pipelines'>
                            <i className="ico icon-edit"></i>
                        </Dropdown.Item>) : (<></>)
                }

                <Dropdown.Menu as={CustomMenu} className="rm-pointers dropdown-menu-lg dropdown-menu-right" ChildClass="pipeline-dropdown" xplacement="bottom-end">
                    {
                        props.pipeline && props.pipeline.length > 0 ?
                            props.pipeline.filter(e => e.value !== 'LeadsBucket').map((rec, index) => {
                                return <Dropdown.Item key={index} className="current-pipeline" onClick={(e) => {
                                    e.preventDefault();
                                    props.handlePipelineChange(rec.value)
                                }}>
                                    {rec.default ? (<div className="pipeline-check">  <i className="ico icon-check"> </i></div>) : (<></>)}
                                    {rec.name}</Dropdown.Item>
                            })
                            : (<></>)
                    }
                    {/* <Dropdown.Divider />
                    <Dropdown.Item eventKey="3"><i className="ico icon-add"></i> New Pipeline </Dropdown.Item>
                    <Dropdown.Item eventKey="4"><i className="ico icon-edit"></i> Edit Pipeline </Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>

            {/**** FILTERS ****/}
            <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={pipelineFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                showPipeline={props.showMore}
                pipeline={props.pipeline}
            >
            </Filters>
            {
                ((searchText && searchText.length > 2) || (props.pipeline.filter(e => e.default)[0] && props.pipeline.filter(e => e.default)[0].value === 'LeadsBucket' && props.view === 'pipeline')) ? (<></>) : (
                    <div className="pipeline-counter mr-3">
                        <div className="counter-nav cursor-pointer">
                            <ul>
                                {
                                    counts.total > 0 ? (
                                        <li><a href="#" className="counter-total" onClick={(e) => {
                                            e.preventDefault();
                                            props.handleStatusFilter('');
                                        }}><Translate text={'Total'} /> <span className="badge badge-pill badge-counter ml-1">{counts.total}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    enquiryStatus.filter(a => a.value !== 'prospectLost').map((sts,index) => {
                                        return counts[sts.value] > 0 ? (
                                            <li key={index}><a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                props.handleStatusFilter(sts.value);
                                            }}> {sts.name} <span className="badge badge-pill badge-counter ml-1" style={{ background: `${sts.value === 'open' ? '#6b717f' : sts.color}` }}>{counts[sts.value]}</span></a></li>
                                        ) : (<Fragment key={index}></Fragment>)
                                    })
                                }
                                {/* {
                                    counts.open > 0 ? (
                                        <li><a href="#" className="counter-open" onClick={(e) => {
                                            e.preventDefault();
                                            props.handleStatusFilter(enquiryStatus.OPEN);
                                        }}> {_.find(enquiryStatus, { value: 'open' }) ? `${_.find(enquiryStatus, { value: 'open' }).name}` : 'Open'} <span className="badge badge-pill badge-counter ml-1">{counts.open}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.won > 0 ? (
                                        <li><a href="#" className="counter-won" onClick={(e) => {
                                            e.preventDefault();
                                            props.handleStatusFilter(enquiryStatus.WON);
                                        }}> {_.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name}` : 'Won'} <span className="badge badge-pill badge-counter ml-1">{counts.won}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.delivered > 0 ? (
                                        <li><a href="#" className="counter-delivered" onClick={(e) => {
                                            e.preventDefault();
                                            props.handleStatusFilter(enquiryStatus.DELIEVERD);
                                        }}> {_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'} <span className="badge badge-pill badge-counter ml-1">{counts.delivered}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.lost > 0 ? (
                                        <li><a href="#" className="counter-lost" onClick={(e) => {
                                            e.preventDefault();
                                            props.handleStatusFilter(enquiryStatus.LOST);
                                        }}> {_.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name}` : 'Lost'} <span className="badge badge-pill badge-counter ml-1">{counts.lost}</span></a></li>
                                    ) : (<></>)
                                } */}
                                {/* <li><a href="#" className="counter-rotten"> Rotten <span className="badge badge-pill badge-counter ml-1">10</span></a></li> */}
                            </ul>
                        </div>
                    </div>
                )
            }

            {
                (!_.isEmpty(props.pipelineCount) || props.pipelineCount > 0) ?
                    (<>
                        <div className="list-count-btn ml-3" >
                            <span>  <Translate text={props.pipelineCount > 1 ? 'Enquiries' : 'Enquiry'} /></span>
                            <span className="list-count"> {props.pipelineCount} </span>
                        </div>
                    </>)
                    : (<></>)
            }

        </div >)
}

export default FilterPanel