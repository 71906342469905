import React, { useState, useEffect } from 'react';
import { CustomTable, PopoverPanel } from '../../components';
import _ from 'lodash';
import Translate from '../../constants/translate';

const cols = [
    { name: 'Color', value: 'color', type: 'colorpicker', mandatory: true, width: '21.25' },
    { name: 'Name', value: 'name', type: 'text', width: '21.25', mandatory: true },
    { name: 'Option', value: 'option', type: 'text', width: '21.25', mandatory: true },
    { name: 'Value', value: 'value', type: 'text', width: '21.25', mandatory: true }
]

const CellColorSettings = (props) => {
    const [popover, setPopover] = useState({})
    const [btnloader, setbtnloader] = useState(false)
    const [state, setState] = useState(props?.deallogSettings?.fieldColors?.[props.params.column.colId] || [])

    useEffect(() => {
        setPopover({ show: true, target: props.params.eGridCell })
    }, [])

    const handleSave = (e) => {
        setbtnloader(true)
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`)
            .set({
                fieldColors: {
                    [props.params.column.colId]: state
                }
            }, { merge: true })
            .then(() => {
                setPopover({ show: false });
                props.handleClose();
            })
    }

    return (
        <PopoverPanel
            showpopover={popover.show}
            targetpopover={popover.target}
            title={'Cell Colors'}
            position={props.position || 'left'}
            id='dealerlog-comments'
            className='popover-deallog-cellcolor popover-deallog-theme'
            closepopover={() => {
                setPopover({ show: false });
                props.handleClose();
                //props.api.stopEditing();
            }}>

            <div>
                <CustomTable
                    cols={cols}
                    subList={state}
                    setSubList={(list) => { setState(list) }}
                    dragDisabled={true}
                />
                <div className="mt-2 w-100 d-inline-block">
                    <button type="button" onClick={handleSave} className="btn btn-primary float-right d-flex align-items-center justify-content-center">
                        <i className="ico icon-email mr-1"></i>   {
                            btnloader ? (
                                <span
                                    className='spinner-border spinner-button mr-1'
                                    role='status'
                                    aria-hidden='true'
                                ></span>
                            ) : (
                                <></>
                            )}
                        <Translate text={'save'} />
                    </button>
                </div>
            </div>
        </PopoverPanel>
    );
};

export default CellColorSettings;